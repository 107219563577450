import moment from "moment";
import React, { useState, useEffect } from "react";
import CloseIcon from "../../../assets/icons/mini-close.svg";
import DropIcon from "../../../assets/icons/drop-down.svg";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";

import "./generateCouponCode.scss";
export default function GenerateCouponCode(props) {
  var randomstring = require("randomstring");
  const { generateCouponCodeModal, setGenerateCouponCodeModal, mineData, thismanager, couponDetails, setSuccess, setEr, setToastmsg } = props;
  const userInfo = Auth.getUserDetail();
  const [couponCode, setCouponCode] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [validDays, setValidDays] = useState("");
  const [validTimes, setValidTimes] = useState(1);
  const [subDiscoutMenu, setSubDiscoutMenu] = useState(false);
  const [discounttype, setDiscountType] = useState("%");
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [error, setError] = useState("");

  function ChangeDiscountType(e, data) {
    setDiscountPrice("");
    if (data === "%") {
      setDiscountType("%");
      setSubDiscoutMenu(false);
    } else {
      setDiscountType("₹");
      setSubDiscoutMenu(false);
    }
  }
  // var discounted = discountPrice + discounttype;

  useEffect(() => {
    validating();
  }, []);

  let validating = (valiD) => {
    if (valiD) {
      if (!thismanager) {
        return moment(new Date()).add(validDays, "day");
      } else {
        return moment(new Date()).add(30, "day");
      }
    }
  };

  const generateCoupon = async () => {
    // if (thismanager) {
    //   let bodyy = {
    //     code: couponCode,
    //     discount: discounted,
    //     validTime: 1,
    //     validity: validating(validTimes),
    //     generatedName: userInfo?._id,
    //     validityTime: 30,
    //   }
    //   await ApiPost(`coupen/manager/coupen/${userInfo?._id}`, bodyy)
    //     .then((res) => {
    //       console.log("coupon", res?.data?.data);
    //       setCoupounDetails((res?.data?.data).reverse());
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    let body = {
      code: couponCode,
      discount: discountPrice,
      discountType: discounttype,
      validTime: validTimes,
      validity: thismanager ? moment(new Date()).add(30, "day") : validating(validTimes),
      generatedName: userInfo?._id,
      validityTime: validDays,
    };

    let alreadyExistCoupon = couponDetails?.length > 0 ? couponDetails?.find((data) => data?.code === couponCode) : false;
    if (alreadyExistCoupon && alreadyExistCoupon?.code) {
      setEr(true);
      setToastmsg("Coupon already exists!");
      setSuccess(true);
      return;
    } else {
      await ApiPost("coupen", body)
        .then((res) => {
          console.log("resis", res);
          mineData();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    // }
    setGenerateCouponCodeModal(!generateCouponCodeModal);
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };
  const couponInput = (e) => {
    // const re = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    // if (e.target.value === "" || re.test(e.target.value.toUpperCase())) {
      if(e.target.value?.length <= 5){
    setCouponCode(e.target.value.toUpperCase());
    setAutoGenerate(false)
      }
    // }
  };

  const checkValidation = () => {
    const re = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    if (!re.test(couponCode)) {
      setError("*Code must be alphanumeric");
    } else {
      setError("");
    }
  };

  const GenerateCoupenCode = () => {
    setAutoGenerate(false);
    let tempCode = randomstring.generate({
      length: 5,
      capitalization: "uppercase",
    });
    const re = /^[0-9]/;

    if (re?.test(tempCode)) {
      setCouponCode(tempCode);
      return;
    } else {
      GenerateCoupenCode();
    }
  };

  const ClearCoupen=()=>{
    setAutoGenerate(true)
    setCouponCode("")
  }
  return (
    <>
      <div className="generate-coupon-modal-wrapper">
        <div className="generate-coupon-modal">
          <div className="generate-coupon-modal-header">
            <div onClick={() => setGenerateCouponCodeModal(!generateCouponCodeModal)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Generate coupon code</h2>
          </div>
          <div className="generate-coupon-modal-body">
            <div className="input">
              <label>Coupon code {error && <span className="validation-error-message"> {error}</span>}</label>
              <div className="input-relative-div">
                <input
                  type="text"
                  placeholder="e.g. BARBERA30"
                  // maxLength={5}
                  value={couponCode}
                  // onKeyPress={couponInput}
                  onInput={couponInput}
                  onBlur={checkValidation}
                />
                {autoGenerate ? (
                  <div className="text-right-alignment" onClick={() => GenerateCoupenCode()}>
                    <a style={{ cursor: "pointer" }}>Auto generate</a>
                  </div>
                ) : (
                  <div className="text-right-alignment" onClick={() => ClearCoupen()}>
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L10 10" stroke="#E66666" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M1 10L10 1" stroke="#E66666" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="disuount-input-grid">
              <div className="disuount-input-grid-items">
                <div className="form-group">
                  <label>
                    Discount <a>{discounttype}</a> off{" "}
                    {
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {/* {errors["discount"]}{" "} */}
                      </span>
                    }
                  </label>
                  {/* {discounttype === "%" && (
                    <input
                      type="text"
                      placeholder="Enter value"
                      value={discountPrice}
                      maxLength={2}
                      onKeyPress={bindInput}
                      onChange={(e) => setDiscountPrice(e.target.value)}
                    />
                  )} */}
                  {/* {discounttype === "₹" && ( */}
                  <input
                    type="number"
                    placeholder="Enter value"
                    value={discountPrice}
                    maxLength={discounttype === "%" ? 2 : 5}
                    onKeyPress={bindInput}
                    onChange={(e) => setDiscountPrice(e.target.value)}
                  />
                  {/* )} */}
                </div>
              </div>
              <div className="disuount-input-grid-items">
                <div className="relative">
                  <div className="form-group relative" onClick={() => setSubDiscoutMenu(!subDiscoutMenu)} style={{ cursor: "pointer" }}>
                    <input style={{ fontFamily: "Roboto, sans-serif" }} type="text" placeholder="%" value={discounttype} />
                    <div className="icon-input-align">
                      <img src={DropIcon} alt="DropIcon" />
                    </div>
                  </div>
                  <div className={!subDiscoutMenu ? "sub-menu-open sub-menu-hidden " : "sub-menu-open sub-menu-show"}>
                    <div className="menu-design-box">
                      {discounttype === "₹" ? (
                        <div className="list-style-design" onClick={(e) => ChangeDiscountType(e, "%")}>
                          <span>%</span>
                        </div>
                      ) : (
                        <div className="list-style-design rs-roboto-change" onClick={(e) => ChangeDiscountType(e, "₹")}>
                          <span>₹</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!thismanager && (
              <>
                <div className="input">
                  <label>Valid for</label>
                  <div className="input-relative-div">
                    <input type="number" placeholder="1" value={validTimes} onKeyPress={bindInput} onChange={(e) => setValidTimes(e.target.value)} />
                    <div className="text-right-alignment">
                      <span>times</span>
                    </div>
                  </div>
                </div>
                <div className="input">
                  <label>Validity</label>
                  <div className="input-relative-div">
                    <input
                      type="number"
                      placeholder="e.g. 30"
                      value={validDays}
                      onKeyPress={bindInput}
                      onChange={(e) => setValidDays(e.target.value)}
                    />
                    <div className="text-right-alignment">
                      <span>days</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="generate-coupon-footer">
            {(!thismanager && validDays === "") ||
            couponCode === "" ||
            discountPrice === "" ||
            error !== "" ||
            (discounttype === "%" ? discountPrice > 58 : discountPrice > 14000) ? (
              <div className="disable-blue-button">
                <button>Generate</button>
              </div>
            ) : (
              <div className="blue-button" onClick={() => generateCoupon()}>
                <button>Generate</button>
              </div>
            )}
            {/* {thismanager && couponCode === "" || discountPrice === "" ? (
              <div className="disable-blue-button">
                <button>Generate</button>
              </div>
            ) : (
              <div className="blue-button" onClick={() => generateCoupon()}>
                <button>Generate</button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
