import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../assets/icons/new-close.svg";
import SelectInput from "../../../selectInput";
import DownIcon from "../../../../assets/icons/gray-down.svg";
import "./addnewplan.scss";
import CountryList from "../../../../helpers/country.json";
import CurrencyList from "../../../../helpers/currrency.json";
import { ApiGet, ApiPost, ApiPut } from "./../../../../helpers/API/ApiData";
import { BasicPlanAPI, EnterprisePlanAPI, ProPlanAPI, toastMessage } from "./PlanAPI";
import Success from "../../../Toaster/Success/Success";

export default function AddNewPlanModal(props) {
  const { toggle, modalType, planData , setAPIResponse , toggleLoading} = props;
  const [errors, setError] = useState({});
  const [planFeture, setPlanFeture] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [status, setStatus] = useState({ Name: "Unpaid" });
  const [accountData, setAccountData] = useState({
    nameOfSalonOwner: "",
    mobileNumber: "",
    businessName: "",
    acronym: "",
    email: "",
    address: "",
    password: "",
  });

  const selectCategoryRef = useRef();
  const selectCurrencyRef = useRef();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const [searchedCountry, setSearchedCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencyCountry, setSelectedCurrencyCountry] = useState("");
  const [countryDropDown, setCountryDropDown] = useState(true);
  const [CurrencyDropDown, setCurrencyDropDown] = useState(true);
  const [allCoutry, setallCoutry] = useState(CountryList);
  const [searchedCurrency, setSearchedCurrency] = useState("");
  const [allCurrency, setallCurrency] = useState(CurrencyList);
  const [planPricing, setplanPricing] = useState({
    basicPlanPrice: null,
    discountedBasicPlanPrice: null,
    proPlanPrice: null,
    proPlanDiscountedPrice: null,
    enterprisePlanPrice: null,
    enterpriesPlanDiscountedPrice: null,
  });
  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };
  useEffect(() => {
    if (modalType == "Update") {
      setSelectedCountry(planData?.[0]);
      setSelectedCurrency(planData?.[1]?.[0]?.razorpaycurrencyName);
      setSelectedCurrencyCode(planData?.[1]?.[0]?.razorpaycurrencycode);
      setplanPricing({
        basicPlanPrice: planData?.[1].find((itm) => itm?.planName == "Basic")?.planRate,
        discountedBasicPlanPrice: planData?.[1].find((itm) => itm?.planName == "Basic")?.disountedRate,
        proPlanPrice: planData?.[1].find((itm) => itm?.planName == "Pro")?.planRate,
        proPlanDiscountedPrice: planData?.[1].find((itm) => itm?.planName == "Pro")?.disountedRate,
        enterprisePlanPrice: planData?.[1].find((itm) => itm?.planName == "Enterprise")?.planRate,
        enterpriesPlanDiscountedPrice: planData?.[1].find((itm) => itm?.planName == "Enterprise")?.disountedRate,
      });
      BasicPlanAPI.updateId = planData?.[1].find((itm) => itm?.planName == "Basic")?._id;
      EnterprisePlanAPI.updateId = planData?.[1]?.find((itm) => itm?.planName == "Enterprise")?._id;
      ProPlanAPI.updateId = planData?.[1]?.find((itm) => itm?.planName == "Pro")?._id
    }
  }, []);

  useEffect(() => {
    let SearchCurrency = CurrencyList?.find(
      (itm) => itm?.code == selectedCurrencyCode
    );
    setSelectedCurrencyCountry(SearchCurrency?.name);
    setSelectedCurrency(SearchCurrency?.code);
  }, [selectedCurrencyCode]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // if (!countryDropDown && selectCategoryRef.current) {
      //   setCountryDropDown(!countryDropDown);
      // }
      // if (!CurrencyDropDown && selectCurrencyRef.current) {
      //   setCurrencyDropDown(!CurrencyDropDown);
      // }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [countryDropDown, CurrencyDropDown]);

  const changeCategoryHandler = (e) => {
    setSearchedCountry(e.target.value);

    var categoryData =
      CountryList.length > 0 &&
      CountryList.filter(
        (rep) =>
          rep.country &&
          rep.country.toLowerCase().includes(e.target.value.toLowerCase())
      );
    if (e.target.value === "") {
      setallCoutry(CountryList);
    } else {
      setallCoutry(categoryData);
    }
  };

  const searchCurrencyHandler = (e) => {
    setSearchedCurrency(e.target.value);

    var CurrencyData =
      CurrencyList.length > 0 &&
      CurrencyList.filter(
        (rep) =>
          (rep.code &&
            rep.code.toLowerCase().includes(e.target.value.toLowerCase())) ||
          (rep.name &&
            rep.name.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    if (e.target.value === "") {
      setallCurrency(CurrencyList);
    } else {
      setallCurrency(CurrencyData);
    }
  };

  const handlePricingChange = (e) => {
    setError({
      ...errors,
      [e.target.name]: "",
    });
    setplanPricing({
      ...planPricing,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!selectedCountry?.trim()) {
      errors.selectedCountry = "Please select a country.";
    }

    if (!selectedCurrency?.trim()) {
      errors.selectedCurrency = "Please select a currency.";
    }

    if (!planPricing?.basicPlanPrice) {
      errors.basicPlanPrice = "Please enter basic plan price ";
    }

    if (!planPricing?.discountedBasicPlanPrice) {
      errors.discountedBasicPlanPrice =
        "Please enter discounted basic plan price";
    } else if (
      planPricing?.basicPlanPrice &&
      Number(planPricing?.discountedBasicPlanPrice) >
        Number(planPricing?.basicPlanPrice)
    ) {
      errors.discountedBasicPlanPrice =
        "Discounted price must be less than actual price";
    }

    if (!planPricing?.proPlanPrice) {
      errors.proPlanPrice = "Please enter pro plan price";
    }

    if (!planPricing?.proPlanDiscountedPrice) {
      errors.proPlanDiscountedPrice = "Please enter pro plan discounted price ";
    } else if (
      planPricing?.proPlanPrice &&
      Number(planPricing?.proPlanDiscountedPrice) >
        Number(planPricing?.proPlanPrice)
    ) {
      errors.proPlanDiscountedPrice =
        "Discounted price must be less than actual price";
    }

    if (!planPricing?.enterprisePlanPrice) {
      errors.enterprisePlanPrice = "Please enter enterprise plan price ";
    }

    if (!planPricing?.enterpriesPlanDiscountedPrice) {
      errors.enterpriesPlanDiscountedPrice =
        "Please enter enterprise plan discounted price";
    } else if (
      planPricing?.enterprisePlanPrice &&
      Number(planPricing?.enterpriesPlanDiscountedPrice) >
        Number(planPricing?.enterprisePlanPrice)
    ) {
      errors.enterpriesPlanDiscountedPrice =
        "Discounted price must be less than actual price";
    }

    return errors;
  };

  const allData = [BasicPlanAPI, ProPlanAPI, EnterprisePlanAPI];

  const handleAddPlan = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      try {
        toggleLoading(true)
        const apiPromises = allData?.map(async (plan, index) => {
          const originalPrice = planPricing[plan?.planRate];
          const discountedPrice = planPricing[plan?.planDiscount];
          const discountPercent =
            ((originalPrice - discountedPrice) / originalPrice) * 100;
          const roundedDiscountPercent =
            Math.round(discountPercent * 100) / 100;
          const data = {
            planName: plan?.planName,
            planDiscount: roundedDiscountPercent,
            country: selectedCountry,
            planRate: originalPrice,
            disountedRate: discountedPrice,
            planDescription: plan?.planDescription,
            planService: plan?.planService,
            razorpaycurrencyName: selectedCurrency,
            razorpaycurrencycode: selectedCurrencyCode,
          };
          if (modalType === "Update") {
            return ApiPut(`monetizeplan/${plan?.updateId}` , data)
          } else if (modalType === "Add") {
            return ApiPost("monetizeplan", data);
          }
        });

        const results = await Promise.allSettled(apiPromises);

        const allSuccess = results.every(
          (result) => result?.value?.status === 200
        );

        const alreadyExists = results.every(
          (result) => result?.value?.status === 208
        );

        if (allSuccess) {
          setAPIResponse(toastMessage[modalType === "Add" ? "allSuccess" : "updateMessage"]);
        } 
        else if(alreadyExists){
          setAPIResponse(toastMessage["alreadyExists"]);
        } else {
          setAPIResponse(toastMessage["defaultError"]);
        }
      } catch (error) {
        setAPIResponse(toastMessage['defaultError']);
        console.log("Error:", error);
      } finally {
        setplanPricing({
          basicPlanPrice: null,
          discountedBasicPlanPrice: null,
          proPlanPrice: null,
          proPlanDiscountedPrice: null,
          enterprisePlanPrice: null,
          enterpriesPlanDiscountedPrice: null,
        });
        setSelectedCountry("");
        setSelectedCurrency("");
        setSelectedCurrencyCode("");
        setTimeout(() => {
          setAPIResponse({
            modal: false,
            status: null,
            toastmsg: "",
          });
        }, 3000);
        toggle();
        toggleLoading(false)
      }
    }
  };

  return (
    <>
      <div className="full-page-modal-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => toggle()}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>
              {modalType === "Add" ? "Add New" : "Update"} Subscription Plan
            </h2>
          </div>

          <div className="blue-button">
            <button onClick={handleAddPlan}>
              {modalType === "Add" ? "Add" : "Update"} Plan
            </button>
          </div>
        </div>
        <div className="full-page-body-top-alignment">
          <div className="container">
            <div className="box-center-alignment">
              <div className="account-data-box">
                <div className="box-title">
                  <p>Plan Details</p>
                </div>
                <div className="box-body-alignment">
                  <div className={"input-grid form-top-align-space"}>
                    <div className="input-grid-items">
                      <div className="form-group">
                        <label>
                          Select Country{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            *{" "}
                            {selectedCountry?.trim() == "" && (
                              <span
                                style={{
                                  color: "red",
                                  top: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                {" "}
                                {errors["selectedCountry"]}{" "}
                              </span>
                            )}
                          </span>{" "}
                        </label>
                        <div
                          className="input-relative-icon"
                          ref={selectCategoryRef}
                        >
                          <div
                            className="relative"
                            onClick={() => {
                              setCountryDropDown(!countryDropDown);
                              setCurrencyDropDown(true);
                            }}
                          >
                            <input
                              type="search"
                              placeholder={
                                !countryDropDown
                                  ? "Type here"
                                  : "Select Country from list"
                              }
                              disabled={modalType === "Update"}
                              value={
                                countryDropDown
                                  ? selectedCountry
                                  : searchedCountry
                              }
                              onChange={(e) => changeCategoryHandler(e)}
                            />
                            <div className="icon-input-align">
                              <img src={DownIcon} alt="DownIcon" />
                            </div>
                          </div>
                          <div
                            className={
                              countryDropDown
                                ? "category-sub-menu-design category-sub-menu-design-hidden"
                                : "category-sub-menu-design category-sub-menu-design-show"
                            }
                          >
                            {allCoutry?.length > 0 ? (
                              allCoutry?.map((con, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="category-sub-menu-option"
                                    onClick={(e) => {
                                      setSelectedCountry(con?.country);
                                      setSelectedCurrencyCode(
                                        con?.currency_code
                                      );
                                      setCountryDropDown(!countryDropDown);
                                    }}
                                  >
                                    <span>{con?.country}</span>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="category-sub-menu-option">
                                <span>{"No Country Found"}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"input-grid form-top-align-space"}>
                    <div className="input-grid-items">
                      <div className="form-group">
                        <label>
                          Select Currency{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            *{" "}
                            {!selectedCurrency && (
                              <span
                                style={{
                                  color: "red",
                                  top: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                {" "}
                                {errors["selectedCurrency"]}{" "}
                              </span>
                            )}
                          </span>{" "}
                        </label>
                        <div
                          className="input-relative-icon"
                          ref={selectCurrencyRef}
                        >
                          <div
                            className="relative"
                            onClick={() => {
                              setCurrencyDropDown(!CurrencyDropDown);
                              setCountryDropDown(true);
                            }}
                          >
                            <input
                              type="search"
                              placeholder={
                                !CurrencyDropDown
                                  ? "Type here"
                                  : "Select Currency from list"
                              }
                              value={
                                CurrencyDropDown
                                  ? selectedCurrency
                                    ? `${selectedCurrency} - ${selectedCurrencyCountry}`
                                    : ""
                                  : searchedCurrency
                              }
                              onChange={(e) => searchCurrencyHandler(e)}
                            />
                            <div className="icon-input-align">
                              <img src={DownIcon} alt="DownIcon" />
                            </div>
                          </div>
                          <div
                            className={
                              CurrencyDropDown
                                ? "category-sub-menu-design category-sub-menu-design-hidden"
                                : "category-sub-menu-design category-sub-menu-design-show"
                            }
                          >
                            {allCurrency?.length > 0 ? (
                              allCurrency?.map((con, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="category-sub-menu-option"
                                    onClick={(e) => {
                                      setSelectedCurrency(con?.code);
                                      setSelectedCurrencyCountry(con?.name);
                                      setCurrencyDropDown(!CurrencyDropDown);
                                    }}
                                  >
                                    <span>
                                      {con?.code}-{con?.name}
                                    </span>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="category-sub-menu-option">
                                <span>{"No Currency Found"}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input">
                    <label>
                      Basic Plan Price{" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        *{" "}
                        {!planPricing?.basicPlanPrice && (
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            {errors["basicPlanPrice"]}{" "}
                          </span>
                        )}
                      </span>{" "}
                    </label>
                    <div className="relative-div">
                      <input
                        type="number"
                        name="basicPlanPrice"
                        value={planPricing?.basicPlanPrice ?? ""}
                        placeholder="15"
                        onChange={(e) => handlePricingChange(e)}
                        onWheel={() => document.activeElement.blur()}
                        onKeyPress={bindInput}
                      />
                      <div className="day-text-alignment">
                        <span>{selectedCurrency}</span>
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <label>
                      Basic Plan Price(Discounted){" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        *{" "}
                        {(!planPricing?.discountedBasicPlanPrice ||
                          errors["discountedBasicPlanPrice"]) && (
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            {errors["discountedBasicPlanPrice"]}{" "}
                          </span>
                        )}
                      </span>{" "}
                    </label>
                    <div className="relative-div">
                      <input
                        type="number"
                        name="discountedBasicPlanPrice"
                        value={planPricing?.discountedBasicPlanPrice ?? ""}
                        placeholder="15"
                        onChange={(e) => handlePricingChange(e)}
                        onWheel={() => document.activeElement.blur()}
                        onKeyPress={bindInput}
                      />
                      <div className="day-text-alignment">
                        <span>{selectedCurrency}</span>
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <label>
                      Pro Plan Price{" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        *{" "}
                        {!planPricing?.proPlanPrice && (
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            {errors["proPlanPrice"]}{" "}
                          </span>
                        )}
                      </span>{" "}
                    </label>
                    <div className="relative-div">
                      <input
                        type="number"
                        name="proPlanPrice"
                        value={planPricing?.proPlanPrice ?? ""}
                        placeholder="15"
                        onChange={(e) => handlePricingChange(e)}
                        onWheel={() => document.activeElement.blur()}
                        onKeyPress={bindInput}
                      />
                      <div className="day-text-alignment">
                        <span>{selectedCurrency}</span>
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <label>
                      Pro Plan Price(Discounted){" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        *{" "}
                        {(!planPricing?.proPlanDiscountedPrice ||
                          errors["proPlanDiscountedPrice"]) && (
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            {errors["proPlanDiscountedPrice"]}{" "}
                          </span>
                        )}
                      </span>{" "}
                    </label>
                    <div className="relative-div">
                      <input
                        type="number"
                        placeholder="15"
                        name="proPlanDiscountedPrice"
                        value={planPricing?.proPlanDiscountedPrice ?? ""}
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => handlePricingChange(e)}
                        onKeyPress={bindInput}
                      />
                      <div className="day-text-alignment">
                        <span>{selectedCurrency}</span>
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <label>
                      Enterprise Plan Price{" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        *{" "}
                        {!planPricing?.enterprisePlanPrice && (
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            {errors["enterprisePlanPrice"]}{" "}
                          </span>
                        )}
                      </span>{" "}
                    </label>
                    <div className="relative-div">
                      <input
                        type="number"
                        name="enterprisePlanPrice"
                        value={planPricing?.enterprisePlanPrice ?? ""}
                        placeholder="15"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => handlePricingChange(e)}
                        onKeyPress={bindInput}
                      />
                      <div className="day-text-alignment">
                        <span>{selectedCurrency}</span>
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <label>
                      Enterprise Plan Price (Discounted){" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        *{" "}
                        {(!planPricing?.enterpriesPlanDiscountedPrice ||
                          errors["enterpriesPlanDiscountedPrice"]) && (
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            {errors["enterpriesPlanDiscountedPrice"]}{" "}
                          </span>
                        )}
                      </span>{" "}
                    </label>
                    <div className="relative-div">
                      <input
                        type="number"
                        placeholder="15"
                        name="enterpriesPlanDiscountedPrice"
                        value={planPricing?.enterpriesPlanDiscountedPrice ?? ""}
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => handlePricingChange(e)}
                        onKeyPress={bindInput}
                      />
                      <div className="day-text-alignment">
                        <span>{selectedCurrency}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
