import React, { useEffect, useState } from "react";
import "./couponCode.scss";
import CloseIcon from "../../../assets/icons/new-close.svg";
import { ApiGet } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";
import moment, { fn } from "moment";

export default function CouponCode(props) {
  const {
    setCouponCodeModalOpen,
    couponCodeModalOpen,
    generateCouponCodeModal,
    setGenerateCouponCodeModal,
    setCouponCodeDetailsModal,
    couponCodeDetailsModal,
    setSelectedList,
    mineData,
    couponDetails,
  } = props;

  const userInfo = Auth.getUserDetail();
  // const [allcoupendata,setAllCoupenData]=useState(couponDetails)

  useEffect(() => {
    // if (userInfo?.role === "Manager") {
    //      gettingCouponDetails();
    // } else {
    mineData();
    // }
  }, []);

  // const gettingCouponDetails = async () => {
  //   await ApiGet("coupen")
  //     .then((res) => {
  //       console.log("coupon", res?.data?.data);
  //       setAllCoupenData((res?.data?.data).reverse());
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleSelectedList = (list) => {
    setSelectedList(list);
  };

  const handleCouponCodeClick = (e, list) => {
    if (list?.codeAccess && list?.codeAccess?.length > 0) {
      handleSelectedList(list);
      setCouponCodeDetailsModal(!couponCodeDetailsModal);
    } else {
      e.preventDefault();
    }
  };

  const isCouponExpired = (coupon) => {
    if (
      moment(moment(new Date()).format("yyyy-MM-DD")).isAfter(moment(coupon?.validity).format("yyyy-MM-DD")) ||
      coupon?.codeAccess?.length >= coupon?.validTime
    ) {
      return true;
    } else {
      return false;
    }
  };

  console.log("validty");

  return (
    <div className="account-modal-manager-wrapper">
      <div className="full-page-header-alignment">
        <div className="header-title">
          <div onClick={() => setCouponCodeModalOpen(!couponCodeModalOpen)}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
          <h2>Coupon code</h2>
        </div>
        <div className="blue-button" onClick={() => setGenerateCouponCodeModal(!generateCouponCodeModal)}>
          <button>Generate new</button>
        </div>
      </div>
      <div className="full-page-body-top-alignment">
        <div className="all-content-alignment">
          <div className="coupon-modal-width">
            <div className="account-manager-table-design">
              <table>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Generated by</th>
                  <th align="left">Coupon code</th>
                  <th align="left">Discount</th>
                  <th align="left">Validity</th>
                  <th align="left">Status</th>
                </tr>
                {couponDetails?.map((list) => {
                  return (
                    <tr>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>{moment(list?.created).format("DD MMM ‘YY")}</span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>{list?.generatedName?.Name}</span>
                        </div>
                      </td>
                      <td align="left" onClick={(e) => handleCouponCodeClick(e, list)}>
                        <div className="normal-text-style">
                          <span className={list?.codeAccess && list?.codeAccess?.length > 0 && "blue-text-style"}>{list?.code}</span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>
                            {list?.discount}
                            {list?.discountType}
                          </span>
                        </div>
                      </td>
                      <td align="left">
                        {isCouponExpired(list) ? (
                          list?.codeAccess?.length > 0 ? (
                            <div className="inactive-text-style">
                              <span>Appied {list?.codeAccess?.length} times</span>
                            </div>
                          ) : (
                            <div className="inactive-text-style">
                              <span>Validity expired!</span>
                            </div>
                          )
                        ) : (
                          <div className="normal-text-style">
                            <span>
                              Valid for {list?.validTime} times
                              <br /> for {list?.validityTime} days
                            </span>
                          </div>
                        )}
                      </td>
                      <td align="left">
                        {isCouponExpired(list) ? (
                          <div className="active-button-style">
                            <button className="expired">Expired</button>
                          </div>
                        ) : (
                          <div className="active-button-style">
                            <button>Active</button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
