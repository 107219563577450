import React from "react";
import ActiveStaffChart from "./activeStaffChart";
import AppointmentsChart from "./appointmentsChart";
import AttendanceChart from "./attendanceChart";
import DifferencesChart from "./differencesChart";
import InvoicesGeneratedChart from "./invoicesGeneratedChart";
import { CategoryScale, defaults } from "chart.js";
import Chart from "chart.js/auto";

function AllDashboardCharts(props) {
  Chart.register(CategoryScale);
  defaults.font.family = "Poppins";

  return (
    <div>
      <InvoicesGeneratedChart {...props} />
      {/* <DifferencesChart {...props} /> */}
      <AppointmentsChart {...props} />
      <ActiveStaffChart {...props} />
      <AttendanceChart {...props} />
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default AllDashboardCharts;
// export default React.memo(AllDashboardCharts, areEqual);
