import React, { useState, useEffect } from "react";
import "./features.scss";
import CloseIcon from "../../../assets/icons/mini-close.svg";
import SelectInput from "../../selectInput/index";
import Auth from "../../../helpers/Auth";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";

export default function Features(props) {
  const {
    toggle,
    getAccountFeaturesList,
    userDetails,
    userPlanDetails,
    featuresLists,
    setFeaturesLists,
    featuresData,
    setSuccess,
    setToastmsg,
    setEr,
  } = props;
  let userInfo = Auth.getUserDetail();
  const [managerDetails, setManagerDetails] = useState(userInfo);
  const [availablePlans, setAvailablePlans] = useState([
    { Name: "Barbera Basic", slug: "Basic", Value: "basic" },
    { Name: "Barbera Pro", slug: "Pro", Value: "professional" },
    { Name: "Barbera Enterprise", slug: "Enterprise", Value: "enterprise" },
  ]);
  const [selectedPlan, setSelectedPlan] = useState({ Name: "", slug: "", Value: "" });
  const [selectedPlanChanged, setSelectedPlanChanged] = useState(false);
  const [selectedPlansFeatures, setSelectedPlansFeatures] = useState([]);
  const [updatedFeaturesList, setUpdatedFeaturesList] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [amount, setAmount] = useState(false);

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const handleSelectAccountPlan = () => {
    setIsChanged(true);
    setSelectedPlanChanged(!selectedPlanChanged);
  };

  const handleFeaturesListChange = (data) => {
    setIsChanged(true);

    let tempUpdatedFeaturesList = updatedFeaturesList?.map((item) => {
      let updatedData = item;
      if (item?.name === data?.name) {
        updatedData = { ...item, isChecked: !item?.isChecked };
      }
      return updatedData;
    });

    setUpdatedFeaturesList(tempUpdatedFeaturesList);

    if (tempUpdatedFeaturesList?.filter((data) => !data?.isChecked)?.length === 0) {
      setSelectedPlan({ Name: "Barbera Pro", slug: "Pro", Value: "professional" });
    } else {
      setSelectedPlan({ Name: "Barbera Basic", slug: "Basic", Value: "basic" });
    }
  };

  const handleSubmitUpdatedFeatures = async () => {
    let payload = featuresLists?.reduce((a, b) => {
      let thisFeatureIncludedData = updatedFeaturesList?.find((data) => data?.name === b?.name);
      if (thisFeatureIncludedData?.name) {
        return { ...a, [b?.slug]: thisFeatureIncludedData?.isChecked };
      } else {
        return { ...a, [b?.slug]: false };
      }
    }, {});

    await ApiPut(`features/${featuresData?._id}`, payload)
      .then(async(res) => {
        setSuccess(true);
        setToastmsg("Features updated successfully.");
        getAccountFeaturesList();
        if(amount > 0){
          let revenueDetail = {
            date: new Date(),
            salonName: userDetails?.accountData[0],
            accountManagerId: userInfo._id,
            validity: "Add-on",
            amount: amount,
          };
          await ApiPost("managerRevenue", revenueDetail)
            .then(async (res) => {})
            .catch((err) => {
              console.log(err);
            });
        }
        toggle();
      })
      .catch((err) => {
        console.error(err);
        setSuccess(true);
        setToastmsg("Error updating features.");
        setEr(true);
      });
  };
  console.log("selectedPlan", userDetails?.accountData[0]);
  useEffect(() => {

    if (!selectedPlan?.Value) {
      return;
    }
    let tempSelectedPlansFeatures = [];
    if (selectedPlan?.Value === "professional") {
      tempSelectedPlansFeatures = featuresLists?.map((data) => {
        return { ...data, isChecked: (data?.type === "professional" || data?.type === "basic") ? true : false };
      });
    }else if (selectedPlan?.Value === "enterprise") {
      tempSelectedPlansFeatures = featuresLists?.map((data) => {
        return { ...data, isChecked: true };
      });
    }
     else {
      tempSelectedPlansFeatures = featuresLists?.map((data) => {
        return { ...data, isChecked: data?.type === selectedPlan?.Value ? true : false };
      });
    }
    setSelectedPlansFeatures(tempSelectedPlansFeatures);
    setUpdatedFeaturesList(tempSelectedPlansFeatures);
  }, [selectedPlanChanged]);

  useEffect(() => {
          // console.log("userPlanDetails", availablePlans, userPlanDetails);
    if (userPlanDetails) {
      // console.log("userPlanDetails", availablePlans, userPlanDetails);
      let tempSelectedPlan = availablePlans?.find((data) => data?.slug === userPlanDetails?.planId?.planName);
      setSelectedPlan(tempSelectedPlan);
   
    }
    setSelectedPlansFeatures(featuresLists);
    setUpdatedFeaturesList(featuresLists);
  }, []);

  return (
    <>
      <div className="account-upgrade-modal-wrapper">
        <div className="account-upgrade-small-modal">
          <div className="account-upgrade-small-modal-header">
            <div className="close-button" onClick={toggle}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Features</h2>
            <div className="save-button">
              <button className={`${!isChanged && "disabled"}`} disabled={!isChanged} onClick={handleSubmitUpdatedFeatures}>
                Save
              </button>
            </div>
          </div>
          <div className="account-upgrade-small-modal-body">
            {userInfo?.role === "SuperAdmin" ? (
              <SelectInput
                name={"Choose plan"}
                value={selectedPlan?.Name}
                setValue={setSelectedPlan}
                DropDown={availablePlans}
                from="featuresModal"
                handleSelectAccountPlan={handleSelectAccountPlan}
              />
            ) : (
              <div className="input">
                <label>Choose plan</label> <input type="text" name="mobileNumber" disabled value={managerDetails?.Name} />
              </div>
            )}
             <div className="input">
                <label>Add-on Amount</label> <input type="number" name="amount"  value={amount}   onWheel={() => document.activeElement.blur()} onChange={(e)=>setAmount(e.target.value)}/>
              </div>
            <div className="feature-list-container">
              <p className="title">Feature list</p>
              <div className="feature-list">
                {updatedFeaturesList?.map((data, index) => {
                  return (
                    <div className={`feature-list-item ${data?.isChecked && "selected"}`} key={index} onClick={(e) => handleFeaturesListChange(data)}>
                      <p>{data?.name}</p>
                      <div className="plan-type">{data?.type === "professional" ? "PRO" :data?.type === "enterprise"?"Enterprise" : "Basic"}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
