import React, { useEffect, useState } from "react";
import "./filtersModal.scss";
import CloseIcon from "../../../assets/icons/new-close1.svg";
import SearchIcon from "../../../assets/icons/new-search.svg";
import CheckedIcon from "../../../assets/icons/Checked.svg";

export default function FiltersModal(props) {
  const { toggle, title, selectedFilter, setselectedFilter, filterOptions } = props;

  return (
    <div>
      <div className="filter-user-modal-bluer">
        <div className="filter-user-modal-md">
          <div className="filter-user-modal-header">
            <div className="header-all-content-alignment">
              <div className="first-box-title-alignment">
                <div onClick={toggle}>
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <h2>Filters: {title}</h2>
              </div>
            </div>
          </div>
          <div className="filter-user-modal-body-content-alignment">
            <div>
              <div className="all-body-text-checkbox-content-alignment">
                {filterOptions.map((option, index) => {
                  return (
                    <div
                      className={`card-box ${option === selectedFilter && "card-box-selected"}`}
                      key={index}
                      onClick={(e) => setselectedFilter(option)}
                    >
                      <span>{option}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
