import React, { useState } from "react";
import "./whatsappPayment.scss";
import CloseIcon from "../../../../assets/icons/Close.svg";
import { ApiPost } from "../../../../helpers/API/ApiData";
import moment from "moment";

export default function WhatsappPayment(props) {
  const { toggle, whatsappRemainingDays, userDetails, whatsappPlan, getWhatsappCompanyData ,userInformation} = props;
  console.log("userInformation",userInformation)

  let whatsappPaymentData = whatsappPlan?.paymentData;
  const [validity, setValidity] = useState();
  const [ammount, setAmmount] = useState();

  const handleValidityInput = (e) => {
    setValidity(e.target.value);
  };

  const handleAmmountInput = (e) => {
    setAmmount(e.target.value);
  };

  const handleAddValidity = async () => {
    let whatsappFinalPrice = parseInt(ammount);

    let bodyy = {
      paymentGatewayId: "cash",
      amount: whatsappFinalPrice,
      companyId: userDetails?.accountData?.[0]?.companyId,
      type: "Whatsapp",
    };

    await ApiPost("campaignPayment", bodyy)
      .then(async (res) => {
        let whatsappPlanDatass;

        let planpayment = [
          {
            planBasePrice: parseInt(whatsappFinalPrice / (1 + 18 / 100)),
            planGstAmount: whatsappFinalPrice - parseInt(whatsappFinalPrice / (1 + 18 / 100)),
            razorpaymentId: res?.data?.data?._id,
            isActive: true,
            companyId: userDetails?.accountData?.[0]?.companyId,
            company_Id: userDetails?.accountData?.[0]?._id,
            paymentAmount: whatsappFinalPrice,
            paymentEndDate: moment().add(validity, "days").toISOString(),
            paymentStartDate: moment().toISOString(),
            razorpaymentId: "cash",
          },
        ];

        whatsappPlanDatass = whatsappPaymentData.concat(planpayment);
        let PlanDetail = {
          finalDate: moment.utc().add(validity, "days").format(),
          paymentData: whatsappPlanDatass,
          companyId: userDetails?.accountData?.[0]?.companyId,
        };

        await ApiPost("whatappPlan", PlanDetail)
          .then(async (res) => {

              let revenueDetail = {
                date: new Date(),
                salonName: userDetails?.accountData[0],
                accountManagerId: userInformation,
                validity: "WhatsApp",
                amount: whatsappFinalPrice,
              };
              await ApiPost("managerRevenue", revenueDetail)
                .then(async (res) => {})
                .catch((err) => {
                  console.log(err);
                });
            
            getWhatsappCompanyData();
            toggle();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="whatsapp-payment-modal-wrapper">
        <div className="wp-payement-modal-md">
          <div className="add-wp-payment-header">
            <div className="add-wp-payment-header-alignment">
              <p>WhatsApp payment</p>
              <div onClick={toggle}>
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
            </div>
          </div>
          <div className="add-wp-payment-body-contnet-alignment">
            <div className="validity-text">
              <p>
                Validity - {whatsappRemainingDays} days (WA) | {userDetails?.noOfDays} days (Pack)
              </p>
            </div>
            <div className="main-input">
              <label>Edit validity</label>
              <div className="input-relative">
                <input type="text" placeholder="Enter value" onChange={handleValidityInput} />
                <div className="text-alignment">
                  <span>days</span>
                </div>
              </div>
            </div>
            <div className="main-input" style={{ padding: "0" }}>
              <label>Amount paid</label>
              <div className="input-relative">
                <input type="text" placeholder="Enter value" onChange={handleAmmountInput} />
                <div className="text-alignment">
                  <span>days</span>
                </div>
              </div>
            </div>
          </div>
          <div className="add-wp-payment-footer-alignment">
            <button
              onClick={handleAddValidity}
              disabled={!ammount}
              style={{
                opacity: ammount && validity ? 1 : 0.5,
                cursor: ammount && validity ? "pointer" : "default",
              }}
            >
              Mark as paid
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
