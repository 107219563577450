import React, { useState } from "react";
import "./addWhatsaooValidity.scss";
import CloseIcon from "../../../../assets/icons/Close.svg";
import moment from "moment";
import { ApiPost } from "../../../../helpers/API/ApiData";
import { bindInput } from "../../../../utils/commonFunctions";

export default function AddWhatsaooValidity(props) {
  const { toggle, whatsappRemainingDays, whatsappPlan, getWhatsappCompanyData } = props;
  const [validity, setValidity] = useState();

  const handleValidityInput = (e) => {
    setValidity(e.target.value);
  };

  const handleAddValidity = async () => {
    let payload = {
      paymentData: whatsappPlan?.paymentData,
      finalDate:
        whatsappRemainingDays == 0
          ? moment(new Date()).add(validity, "days").toISOString()
          : moment(whatsappPlan?.finalDate).add(validity, "days").toISOString(),
      companyId: whatsappPlan?.companyId,
    };
    await ApiPost("whatappplan", payload)
      .then((res) => {
        toggle(true);
        getWhatsappCompanyData();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <div className="add-wharsapp-validity-all-content-wrapper">
        <div className="add-whatsapp-box-md">
          <div className="add-whatsapp-header">
            <div className="add-whatsapp-header-content-alignment">
              <p>Add WhatsApp validity</p>
              <div onClick={toggle}>
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
            </div>
          </div>
          <div className="add-wharsapp-body">
            <div className="current-validity">
              <p>Current validity - {whatsappRemainingDays} days </p>
            </div>
            <div className="add-validity">
              <label>Add validity</label>
              <div className="relative-input">
                <input type="text" placeholder="Enter value" onKeyDown={bindInput} onChange={handleValidityInput} />
                <div className="days-text-alignment">
                  <span>days</span>
                </div>
              </div>
            </div>
          </div>
          <div className="add-whatsapp-footer">
            <button
              onClick={handleAddValidity}
              disabled={!validity}
              style={{
                opacity: !validity && 0.5,
                cursor: !validity && "default",
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
