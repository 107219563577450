import moment from "moment";
import React, { useState } from "react";
import CloseIcon from "../../../assets/icons/mini-close.svg";
import { ApiPost } from "../../../helpers/API/ApiData";
import "./addValidity.scss";
export default function AddValidity(props) {
  const {setAddValidityModal, addValidityModal,userDetails,toggle} = props;
  const [enable,setEnable]=useState(false)
  const [validity,setValidity]=useState()

  const ChangeValidty=(e)=>{
    setValidity(e.target.value)
    if(e.target.value === "" || e.target.value == 0){
      setEnable(false)
    }else{
      setEnable(true)
    }
  }

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const handleAddValidty=async()=>{
    var beginningtime = moment(moment(userDetails?.monetize[0]?.finalDate).format("yyyy-MM-DD"));
    var endtime = moment(moment(new Date()).format("yyyy-MM-DD"))

    let PlanDetail
    if(userDetails?.monetize?.length > 0 ){
     PlanDetail = {
      finalDate: beginningtime.isBefore(endtime) ? moment.utc(new Date()).add(validity, "days").format() :moment.utc(userDetails?.monetize[0]?.finalDate).add(validity, "days").format(),
      paymentData:userDetails?.monetize[0]?.paymentData,
      companyId: userDetails?.monetize[0]?.companyId,
    };
  }else{
    PlanDetail = {
      finalDate: moment.utc(new Date()).add(validity, "days").format(),
      paymentData:[],
      companyId: userDetails?.accountData[0]?.companyId,
    };
  }
  
    await ApiPost("monetize", PlanDetail)
    .then((res) => {
      toggle(true)
    })
    .catch((err) => {
      console.log("err", err);
    });
  }

  return (
    <>
      <div className="add-validity-modal-wrapper">
        <div className="small-modal">
          <div className="small-modal-header">
            <div onClick={()=>setAddValidityModal(!addValidityModal)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h1>Add validity</h1>
          </div>
          <div className="small-body-alignment">
            <div className="body-text-style">
              <p>Current validity - {userDetails?.noOfDays} days </p>
            </div>
            <div className="input">
              <label>Account validity</label>
              <div className="input-days-relative">
                <input type="number" placeholder="Enter value" value={validity} onChange={(e)=>ChangeValidty(e)}      />
                <div className="days-text-alignment">
                  <span>days</span>
                </div>
              </div>
            </div>
          </div>
          <div className="small-modal-footer">
            <div className="blue-button">
            {enable? 
            <button onClick={()=>handleAddValidty()}>Save Changes</button>:
              <button style={{opacity :0.5}}>Save Changes</button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
