import React, { useEffect, useState, useRef, useMemo } from "react";
import "./revenueModal.scss";
import CloseIcon from "../../../assets/icons/new-close.svg";
import CalendarIcon from "../../../assets/icons/calendar_blue.svg";
import EditIcon from "../../../assets/icons/Edit.svg";
import RemoveIcon from "../../../assets/icons/remove-new-icon.svg";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";
import moment from "moment";
import PropTypes from "prop-types";
import Popper from "popper.js";
import DatePicker from "react-datepicker";
import { sumAndSeggerate } from "../../../utils/commonFunctions";

export default function RevenueModal(props) {
  const { setRevenueModalOpen, revenueModalOpen } = props;
  const userInfo = Auth.getUserDetail();
  const [allRevenues, setAllRevenues] = useState([]);
  const [allRevenue, setAllRevenue] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState({});
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const wrapperRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [managerDetails, setManagerDetails] = useState([]);
  const [value, setValue] = useState("All account managers");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("month")._d
  );

  const handleOnChange = (dates) => {
    const startOfMonth = moment(dates).startOf("month")._d;
    const endOfMonth = moment(dates).endOf("month")._d;
    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
    console.log("startOfMonth", startOfMonth, endOfMonth);
    // setEndDate(end);
  };

  useEffect(() => {
    if (endDate) {
      handleAllRevenue();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    gettingManagerDetails();
  }, []);

  useEffect(() => {
    handleManagerRevene();
  }, [value]);

  useMemo(() => {
    const revenueSum = sumAndSeggerate(allRevenue);
    setTotalRevenue(revenueSum);
  }, [allRevenue]);

  const handleManagerRevene = () => {
    if (value === "All account managers") {
      setAllRevenue(allRevenues);
    } else {
      let filterData = allRevenues?.filter(
        (obj) =>
          obj.accountManagerId?.Name &&
          obj.accountManagerId?.Name.toLowerCase().includes(value.toLowerCase())
      );
      setAllRevenue(filterData);
    }
  };

  const gettingManagerDetails = async () => {
    await ApiGet("account/manager/getAllmanager")
      .then((res) => {
        // Admin added for select manager Dropdown
        let finalData = [{ count: 0, data: userInfo }].concat(res?.data?.data);
        setManagerDetails(finalData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAllRevenue = async () => {
    let temEndDate = moment(endDate).add(1, "days")._d;
    let DataInterval = {
      startTime: moment(startDate).format("YYYY-MM-DD"),
      endTime: moment(temEndDate).format("YYYY-MM-DD"),
    };
    if (userInfo?.role === "Manager") {
      await ApiPost(`managerRevenue/marketing/${userInfo?._id}`, DataInterval)
        .then((res) => {
          console.log(res.data.data?.reverse());
          setAllRevenue(res?.data?.data.reverse());
          setAllRevenues(res?.data?.data.reverse());
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      await ApiPost("managerRevenue/datewise/data", DataInterval)
        .then((res) => {
          console.log(res.data.data?.reverse());
          setAllRevenue(res?.data?.data?.reverse());
          setAllRevenues(res?.data?.data?.reverse());
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownOpen(true);
  };
  const closeDropdownPopover = () => {
    setDropdownOpen(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);
  RevenueModal.propTypes = {
    children: PropTypes.element.isRequired,
  };

  const handleSelectInput = (data) => {
    setValue(data);
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="account-modal-manager-wrapper">
      <div className="full-page-header-alignment">
        <div className="header-title">
          <div onClick={() => setRevenueModalOpen(!revenueModalOpen)}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
          <h2>Revenue</h2>
        </div>
        <div className="new-statement-modal-desgin-alignment">
          <img src={CalendarIcon} alt="CalendarIcon" />
          <span className="date-picker-style">
            {/* <DatePicker
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Date"
              dateFormat="d MMM ''yy"
              onChange={handleOnChange}
              onKeyDown={(e) => e.preventDefault()}
              selectsRange
              fixedHeight
            /> */}
            <DatePicker
              selected={startDate}
              onChange={(date) => handleOnChange(date)}
              onKeyDown={(e) => e.preventDefault()}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              placeholderText="Month"
              fixedHeight
            />
          </span>
        </div>
      </div>
      <div className="full-page-body-top-alignment">
        <div className="all-content-alignment">
          <div className="account-modal-width">
            <div className="sub-header-modal-title ">
              <h5>
                Total revenue -{" "}
                {totalRevenue
                  ? Object.entries(totalRevenue)?.map(([currency, sum]) => (
                      <>
                        <strong>{currency}:</strong> {sum}, {" "}
                      </>
                    ))
                  : 0}
              </h5>
              {userInfo?.role !== "Manager" && (
                <div
                  className="relative-custom-select"
                  ref={wrapperRef}
                  onClick={() => {
                    dropdownOpen
                      ? closeDropdownPopover()
                      : openDropdownPopover();
                  }}
                >
                  <div
                    className="search-relative select-relative-div"
                    ref={btnDropdownRef}
                  >
                    <input
                      type="text"
                      style={{ cursor: "pointer" }}
                      disabled
                      value={value}
                    />
                    <div className="search-icon-alignment">
                      <svg
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 0.5L4 3.5L7 0.5"
                          stroke="#1479FF"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <div
                    className={
                      dropdownOpen
                        ? "custom-select-react-dropdown custom-select-react-dropdown-open"
                        : "custom-select-react-dropdown custom-select-react-dropdown-hidden"
                    }
                    ref={popoverDropdownRef}
                  >
                    <div
                      className="all-menu-alignment"
                      onClick={(e) => handleSelectInput("All account managers")}
                    >
                      <div className="menu-list">
                        <span>{"All account managers"}</span>
                      </div>
                    </div>
                    {managerDetails?.map((drop) => {
                      return (
                        <div
                          className="all-menu-alignment"
                          onClick={(e) => handleSelectInput(drop?.data?.Name)}
                        >
                          <div className="menu-list">
                            <span>{drop?.data?.Name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="account-manager-table-design">
              <table>
                <tr>
                  <th align="left">#</th>
                  <th align="left">Date</th>
                  <th align="left">Salon name</th>
                  <th align="left">Account manager</th>
                  <th align="left">Validity</th>
                  <th align="left">Amount</th>
                </tr>
                {allRevenue.map((elem, i) => {
                  return (
                    <tr>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>{i + 1}</span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>{moment(elem?.date).format("DD MMM yy")}</span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>{elem?.salonName?.businessName}</span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>{elem?.accountManagerId?.Name}</span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="normal-text-style">
                          <span>
                            {elem?.validity}{" "}
                            {elem?.validity !== "Add-on" && "days"}
                          </span>
                        </div>
                      </td>
                      <td align="left">
                        <div className="amount-text-style">
                          <span>
                            <a>
                              {elem?.currency == "INR" ? "₹" : elem?.currency}
                            </a>{" "}
                            {elem?.amount}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
