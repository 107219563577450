import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/icons/new-close.svg";
import SearchIcon from "../../../assets/icons/small-search.svg";
import "./planlist.scss";
import { ApiDelete, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import EditIcon from "../../../assets/icons/Edit.svg";
import DeleteIcon from "../../../assets/icons/Delete.svg";
import Success from "../../Toaster/Success/Success";
import AddNewPlanModal from "./AddNewPlan";
import DeleteConfirmationModal from "../../Toaster/DeleteConfirmationModal";
import Loader from "./../../common/Loader";
import { toastMessage } from "./AddNewPlan/PlanAPI";

export default function PlanList(props) {
  const { setPlanModal } = props;
  const [search, setSearch] = useState("");
  const [allplanData, setAllPlanData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [planData, setPlanData] = useState([]);
  const [planModalData, setPlanModalData] = useState({
    status: null,
    modal: false,
    planData: null,
  });
  const [apiResponse, setAPIResponse] = useState({
    modal: false,
    status: null,
    toastmsg: "",
  });
  const [deleteModalData, setDeleteModalData] = useState({
    modal: false,
    deleteText: null,
    deleteData: null,
  });

  const FilterPlanData = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setPlanData(allplanData);
    } else {
      let filterData = allplanData?.filter((obj) =>
        obj?.[0].toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPlanData(filterData);
    }
  };

  const gettingPlanDetails = async () => {
    await ApiPost("monetizePlan/countrywise")
      .then((res) => {
        setAllPlanData(res?.data?.data);
        setPlanData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleModal = () => {
    setPlanModalData({
      ...planModalData,
      modal: !planModalData?.modal,
      status: null,
      planData: null,
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModalData({
      ...deleteModalData,
      modal: !deleteModalData?.modal,
    });
  };

  useEffect(() => {
    gettingPlanDetails();
  }, [loading]);

  const HandlePlanModal = (status, planData) => {
    setPlanModalData({
      ...planModalData,
      modal: !planModalData?.modal,
      status: status,
      planData: planData ?? null,
    });
  };

  const handleDeleteConfirmation = (ele) => {
    setDeleteModalData({
      ...deleteModalData,
      deleteText: "Subscription Plan",
      modal: !deleteModalData?.data,
      deleteData: ele,
    });
  };

  const handleDeletePlan = async () => {
    toggleDeleteModal()
    const deleteItems = [
      deleteModalData?.deleteData?.[1]?.[0]?._id,
      deleteModalData?.deleteData?.[1]?.[1]?._id,
      deleteModalData?.deleteData?.[1]?.[2]?._id,
    ];

    try {
      setLoading(true)
      const deleteAPIPromise = deleteItems?.map((deleteItems) => {
        return ApiPut(`monetizeplan/${deleteItems}`, { isActive: false });
      });
  
      const results = await Promise.allSettled(deleteAPIPromise);
  
      const allSuccess = results.every((result) => result?.value?.status === 200);
  
      if (allSuccess) {
        setAPIResponse(toastMessage["deleteMessage"]);
      } else{
        setAPIResponse(toastMessage['defaultError'])
      } 
    } catch (error) {
      console.log("🚀 ~ handleDeletePlan ~ error:", error)
    } finally {
      setDeleteModalData({
        modal: false,
        deleteText: null,
        deleteData: null,
      })
      setTimeout(() => {
        setAPIResponse({
          modal: false,
          status: null,
          toastmsg: "",
        });
      }, 3000);
      setLoading(false)
    }

    
  };

  const toggleLoading = (loadingState) => {
    setLoading(loadingState)
  }
  return (
    <>
    {loading && <Loader/>}
      <div className="plan-modal-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => setPlanModal(false)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>User Plan List</h2>
          </div>
          <div className="blue-button" onClick={() => HandlePlanModal("Add")}>
            <button>Add new plan</button>
          </div>
        </div>
        <div className="full-page-body-top-alignment">
          <div className="all-content-alignment">
            <div className="user-modal-all-data-width">
              <div className="sub-header-alignment">
                <div className="manager-filter-align">
                  <h5>Total number of country - {planData?.length} </h5>
                </div>

                <div>
                  <div className="search-relative">
                    <input
                      type="text"
                      placeholder="Search country"
                      value={search}
                      onChange={(e) => FilterPlanData(e)}
                    />
                    <div className="search-icon-alignment">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="user-data-table-show">
                <table>
                  <tr>
                    <th align="left">#</th>
                    <th align="left">Country</th>
                    <th align="left">Currency</th>
                    <th align="left">Basic Price</th>
                    <th align="left">Pro Price</th>
                    <th align="left">Enterprise Price</th>
                    <th align="left"></th>
                    <th align="left"></th>
                  </tr>
                  {planData.map((elem, i) => {
                    return (
                      <tr style={{ cursor: "pointer" }}>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{i + 1}</span>
                          </div>
                        </td>{" "}
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.[0]}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.[1]?.[0]?.razorpaycurrencycode}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.[1].find((itm) => itm?.planName == "Basic")?.disountedRate}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.[1].find((itm) => itm?.planName == "Pro")?.disountedRate}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.[1].find((itm) => itm?.planName == "Enterprise")?.disountedRate}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div onClick={() => HandlePlanModal("Update", elem)}>
                            <img src={EditIcon} alt="EditIcon" />
                          </div>
                        </td>
                        <td align="left">
                          {elem?.[0] == "India" || elem?.[1]?.[0]?.default ? null : (
                            <div onClick={() => handleDeleteConfirmation(elem)}>
                              <img src={DeleteIcon} alt="DeleteIcon" />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
        {planModalData?.modal && (
          <AddNewPlanModal
            toggle={toggleModal}
            modalType={planModalData?.status}
            planData={planModalData?.planData}
            apiResponse={apiResponse}
            setAPIResponse={setAPIResponse}
            toggleLoading={toggleLoading}
          />
        )}
      </div>
      {apiResponse?.modal && (
        <Success
          modal={apiResponse?.modal}
          toastmsg={apiResponse?.toastmsg}
          er={apiResponse?.status}
        />
      )}
      {deleteModalData?.modal && (
        <DeleteConfirmationModal
          modal={deleteModalData?.data}
          deleteName={deleteModalData?.deleteText}
          toggle={toggleDeleteModal}
          handleDeleteData={handleDeletePlan}
        />
      )}
    </>
  );
}
