import React, { useState } from "react";
import "./managerDashboard.scss";
import RightIcon from "../../assets/icons/right.svg";
import { logout } from "../../utils/auth.util";
import UserModal from "../modals/userModal";
import { ApiGet } from "../../helpers/API/ApiData";
import RevenueModal from "../modals/revenueModal";
import CouponCode from "../modals/couponCode";
import Auth from "../../helpers/Auth";
import CouponCodeDetails from "../modals/couponCodeDetails";
import GenerateCouponCode from "../modals/generateCouponCode";


export default function ManagerDashboard(props) {
  const { userInfo, thismanager } = props;
  const ManagerId = Auth.getUserDetail();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [revenueModalOpen, setRevenueModalOpen] = useState(false);
  const [generateCoupon, setgenerateCoupon] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [mineRole, setMineRole] = useState("");
  const [couponDetails, setCoupounDetails] = useState([]);
  const [userInformationModalOpen, setUserInformationModalOpen] = useState(false);
  const [generateCouponCodeModal, setGenerateCouponCodeModal] = useState(false);
  const [couponCodeDetailsModal, setCouponCodeDetailsModal] = useState(false);
  const [selectedList, setSelectedList] = useState();
  
  const handlingUsers = () => {
    setOpenUserModal(true);
  };

  const handlingRevenue = () => {
    setRevenueModalOpen(true);
  };

  const handleCouponCode = () => {
    setCoupon(true);
    // setgenerateCoupon(true);
  };
  
  const gettingCoupons = async () => {
    await ApiGet(`coupen/manager/coupen/${ManagerId?._id}`)
      .then((res) => {
        console.log("coupon", res?.data?.data);
        setCoupounDetails((res?.data?.data).reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="modal-body">
      <div className="container">
        <div className="modal-body-top-align">
          <div className="generate-box-center">
            <div className="generate-box">
              <div className="title-text">
                <h3>Hello, {userInfo?.Name}</h3>
              </div>
              <div className="new-invoice-heading">
                <div className="service-alignment" onClick={() => handlingUsers()}>
                  <div className="service-name">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.8337 21.875V19.7917C20.8337 18.6866 20.3947 17.6268 19.6133 16.8454C18.8319 16.064 17.7721 15.625 16.667 15.625H8.33366C7.22859 15.625 6.16878 16.064 5.38738 16.8454C4.60598 17.6268 4.16699 18.6866 4.16699 19.7917V21.875"
                        stroke="#193566"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5007 11.4583C14.8018 11.4583 16.6673 9.59285 16.6673 7.29167C16.6673 4.99048 14.8018 3.125 12.5007 3.125C10.1995 3.125 8.33398 4.99048 8.33398 7.29167C8.33398 9.59285 10.1995 11.4583 12.5007 11.4583Z"
                        stroke="#193566"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <span>Users</span>
                  </div>
                  <div className="more-service">
                    <img src={RightIcon} alt="RightIcon" />
                  </div>
                </div>
                <div className="service-alignment" onClick={() => handlingRevenue()}>
                  <div className="service-name">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.7662 4.39834C14.7673 3.11939 12.1594 2.0835 8.94616 2.0835C5.73288 2.0835 3.12733 3.12054 3.125 4.39834M3.125 4.39834C3.125 5.67728 5.73055 6.71317 8.94616 6.71317C12.1618 6.71317 14.7673 5.67728 14.7673 4.39834L14.7673 13.3798M3.125 4.39834V18.2873C3.12616 19.5662 5.73172 20.6021 8.94616 20.6021C10.5051 20.6021 11.9126 20.3556 12.9569 19.9586M3.12616 9.02799C3.12616 10.3069 5.73172 11.3428 8.94733 11.3428C12.1629 11.3428 14.7685 10.3069 14.7685 9.02799M13.0268 15.3071C11.9767 15.7179 10.5365 15.9726 8.94616 15.9726C5.73172 15.9726 3.12616 14.9367 3.12616 13.6577M21.3825 14.0258C23.4281 16.0594 23.4281 19.3581 21.3825 21.3916C19.3369 23.4252 16.0189 23.4252 13.9733 21.3916C11.9278 19.3581 11.9278 16.0594 13.9733 14.0258C16.0189 11.9922 19.3369 11.9922 21.3825 14.0258Z"
                        stroke="#193566"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Revenue</span>
                  </div>
                  <div className="more-service">
                    <img src={RightIcon} alt="RightIcon" />
                  </div>
                </div>
                <div className="service-alignment" onClick={() => handleCouponCode()}>
                  <div className="service-name">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_721_6657)">
                        <path
                          d="M12.9081 2.4629L20.9418 3.35782L21.7978 11.7567L12.6263 21.3451C12.2033 21.7874 11.6638 22.0717 11.1265 22.1354C10.5892 22.1991 10.0981 22.0371 9.76129 21.685L3.41146 15.0466C3.07465 14.6944 2.91968 14.181 2.98064 13.6193C3.04161 13.0576 3.31352 12.4936 3.73655 12.0513L12.9081 2.4629Z"
                          stroke="#193566"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <ellipse cx="18.087" cy="6.13636" rx="1.08696" ry="1.13636" fill="#193566" />
                        <path
                          d="M7.30281 14.6077C7.00006 14.2911 6.85075 13.9529 6.8549 13.5931C6.85905 13.2245 7.00006 12.895 7.27793 12.6045C7.5558 12.314 7.86892 12.1687 8.2173 12.1687C8.56568 12.1601 8.89124 12.314 9.194 12.6305C9.49675 12.947 9.64606 13.2896 9.64191 13.6581C9.64191 14.0223 9.50297 14.3497 9.2251 14.6402C8.94723 14.9307 8.63203 15.0781 8.27951 15.0824C7.93113 15.0824 7.60557 14.9242 7.30281 14.6077ZM10.208 9.71032L12.0557 16.832L11.3589 17.5604L9.51127 10.4387L10.208 9.71032ZM7.70718 13.0533C7.4459 13.3264 7.49566 13.6516 7.85648 14.0288C8.22145 14.4104 8.53457 14.4646 8.79585 14.1914C8.92027 14.0613 8.97212 13.9161 8.95138 13.7557C8.93064 13.5866 8.82696 13.4045 8.64033 13.2093C8.27951 12.8321 7.96846 12.7801 7.70718 13.0533ZM12.3792 14.6207C12.0764 14.3042 11.9271 13.966 11.9312 13.6061C11.9354 13.2375 12.0764 12.908 12.3543 12.6175C12.628 12.3313 12.939 12.1883 13.2874 12.1883C13.6358 12.1796 13.9614 12.3335 14.2641 12.65C14.5669 12.9665 14.7162 13.3091 14.712 13.6776C14.712 14.0418 14.5752 14.367 14.3014 14.6532C14.0236 14.9437 13.7084 15.0911 13.3558 15.0954C13.0075 15.0954 12.6819 14.9372 12.3792 14.6207ZM12.7773 13.0728C12.516 13.3459 12.5658 13.6711 12.9266 14.0483C13.2874 14.4256 13.5985 14.4776 13.8598 14.2044C14.121 13.9313 14.0713 13.6061 13.7104 13.2289C13.3496 12.8516 13.0386 12.7996 12.7773 13.0728Z"
                          fill="#193566"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_721_6657">
                          <rect width="25" height="25" fill="white" transform="translate(0 25) rotate(-90)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Coupon codes</span>
                  </div>
                  <div className="more-service">
                    <img src={RightIcon} alt="RightIcon" />
                  </div>
                </div>
              </div>
              <div className="setting-button-alignment">
                <button onClick={() => logout()}>
                  {" "}
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.375 21.875H5.20833C4.6558 21.875 4.12589 21.6555 3.73519 21.2648C3.34449 20.8741 3.125 20.3442 3.125 19.7917V5.20833C3.125 4.6558 3.34449 4.12589 3.73519 3.73519C4.12589 3.34449 4.6558 3.125 5.20833 3.125H9.375M16.6667 17.7083L21.875 12.5M21.875 12.5L16.6667 7.29167M21.875 12.5H9.375"
                      stroke="#E66666"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
        {openUserModal && (
          <UserModal
            setUserInformationModalOpen={setUserInformationModalOpen}
            userInformationModalOpen={userInformationModalOpen}
            setAddUserModalOpen={setOpenUserModal}
          />
        )}
        {revenueModalOpen && <RevenueModal setRevenueModalOpen={setRevenueModalOpen} revenueModalOpen={revenueModalOpen} />}
        {coupon && (
          <CouponCode
            mineData={gettingCoupons}
            couponDetails={couponDetails}
            couponCodeModalOpen={coupon}
            setCouponCodeDetailsModal={setCouponCodeDetailsModal}
            setCouponCodeModalOpen={setCoupon}
            setGenerateCouponCodeModal={setGenerateCouponCodeModal}
            generateCouponCodeModal={generateCouponCodeModal}
            setSelectedList={setSelectedList}
            couponCodeDetailsModal={couponCodeDetailsModal}
          />
        )}
        {couponCodeDetailsModal && (
        <CouponCodeDetails
          setCouponCodeDetailsModal={setCouponCodeDetailsModal}
          couponCodeDetailsModal={couponCodeDetailsModal}
          selectedList={selectedList}
        />
      )}
        {generateCouponCodeModal && (
          <GenerateCouponCode
            setGenerateCouponCodeModal={setGenerateCouponCodeModal}
            generateCouponCodeModal={generateCouponCodeModal}
            mineData={gettingCoupons}
            thismanager={thismanager}
            setCoupounDetails={setCoupounDetails}
          />
        )}
        {/* setCouponCodeModalOpen={setCoupon} couponCodeModalOpen={coupon} */}
      </div>
    </div>
  );
}
