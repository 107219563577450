import React, { useState } from "react";
import Auth from "../../helpers/Auth";
import AdminDashboard from "../adminDashboard";
import ManagerDashboard from "../managerDashboard";

export default function Home() {
  const userInfo = Auth.getUserDetail();
  const [thismanager, setThisManager] = useState(true);

  return <div>{userInfo?.role === "Manager" ? <ManagerDashboard thismanager={thismanager} userInfo={userInfo} /> : <AdminDashboard />}</div>;
}
