import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Layout from "./Layout";

import UnAuthRoutes from "./UnAuthRoutes";

export default function Routes() {
  const loading = () => "Loading...";
  return (
    <>
      <BrowserRouter>
        <Switch>
        <Suspense fallback={loading()}>
          <Route
            path="/"
            render={() => {
              if (!Auth.isUserAuthenticated()) return <UnAuthRoutes />
              return <Layout />
            }}
          />
        </Suspense>
        </Switch>
      </BrowserRouter>
    </>
  );
}
