import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from "../../../assets/icons/new-close.svg";
import SearchIcon from "../../../assets/icons/small-search.svg";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";
import Success from "../../Toaster/Success/Success";
import AddUserModal from "../AddUserModal";
import UserInformationModal from "../userInformationModal";
import Popper from "popper.js";
import EditIcon from "../../../assets/icons/Edit.svg";
import FilterIcon from "../../../assets/icons/reminder-filter-icon.svg";
import CloseTab from "../../../assets/icons/CloseTab-red.svg";

import "./userModal.scss";
import axios from "axios";
export default function OldUserData(props) {
  const {  setAllUserModalOpen } = props;
  const userInfo = Auth.getUserDetail();
  const [allAdminOwner, setAllAdminOwner] = useState([]);
  const [allAdminOwners, setAllAdminOwners] = useState([]);
  const [search, setSearch] = useState("");
 

  useEffect(() => {
    handleAllRole();

  }, []);


  const handleAllRole = async () => {

      await axios.get("https://api.barbera.io/api/account")
        .then(async (res) => {
          setAllAdminOwner(res?.data?.data);
          setAllAdminOwners(res?.data?.data);
     
        })
        .catch((err) => {
          console.log("err", err);
        });
    
  };

  const FilterUserData = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setAllAdminOwner(allAdminOwners);
    } else {
      let filterData = allAdminOwners?.filter(
        (obj) =>
          (obj?.businessName && obj?.businessName.toLowerCase().includes(e.target.value.toLowerCase())) ||
          (obj?.mobileNumber && obj?.mobileNumber.toString().includes(e.target.value.toString()))
      );
      setAllAdminOwner(filterData);
    }
  };

  

  
  

 

  return (
    <>
      <div className="user-modal-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => setAllUserModalOpen(false)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Users</h2>
          </div>
          
        </div>
        <div className="full-page-body-top-alignment">
          <div className="all-content-alignment">
            <div className="user-modal-all-data-width">
              <div className="sub-header-alignment">
                <div className="manager-filter-align">
                  <h5>Total number of user accounts created - {allAdminOwner?.length} </h5>
               
                </div>

                <div>
                  <div className="search-relative">
                    <input type="text" placeholder="Search user" value={search} onChange={(e) => FilterUserData(e)} />
                    <div className="search-icon-alignment">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                  </div>
                </div>
              </div>
            

              <div className="user-data-table-show">
                <table>
                  <tr>
                    <th align="left">#</th>
                    <th align="left">Date</th>
                    <th align="left">Salon name</th>
                    <th align="left">Type</th>
                    <th align="left">Mobile number</th>
                    <th align="left">Address</th>
                    {userInfo?.role !== "Manager" && <th align="center"></th>}
                  </tr>
                  {allAdminOwner.map((elem, i) => {
                    return (
                      <tr
                        style={{ cursor: "pointer" }}
                     
                      >
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{i + 1}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{moment(elem?.created).format("DD MMM YY")}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.businessName}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.role}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.mobileNumber}</span>
                          </div>
                        </td>
                        <td align="left" style={{maxWidth:"300px"}}>
                          <div className="normal-text-style">
                            <span>{elem?.address}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
               
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
