import API_PROD from "./api-prod";
import API_STAGE from "./api-stage";
import API_DEV from "./api-dev";
const hostname = window.location.hostname;




export const API =
  hostname === "localhost"
    ? API_DEV
    : hostname === "dev-admin.barbera.io"
    ? API_DEV 
    : hostname === "admin.barbera.io"
    ? API_PROD
    : hostname === "stage-admin.barbera.io"
    ? API_STAGE
    : API_DEV;
