import React, { useState } from "react";
import "./Toaster.scss";
import UserIcon from "../../assets/icons/user.svg";
import DeleteIcon from "../../assets/icons/white-delete.svg";
import { ApiPut } from "../../helpers/API/ApiData";

export default function Delete(props) {
  const { toggle,deleteData,activeData } = props;

  const [clicked, setClicked] = useState(false);

  const handlerDelete=()=>{
    if(activeData){
      ApiPut(`account/${activeData?.accountData && activeData?.accountData[0]?._id ?  activeData?.accountData && activeData?.accountData[0]?._id : activeData?._id}`, {isActive: true})
      .then((resp) => {
        toggle(true)
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
    ApiPut(`account/${deleteData?.accountData && deleteData?.accountData[0]?._id ?  deleteData?.accountData && deleteData?.accountData[0]?._id : deleteData?._id}`, {isActive: false})
      .then((resp) => {
        toggle(true)
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  return (
    <>
      <div className="toaster-modal">
        <div className={props.modal ? "modal-design-toster toster-show" : " modal-design-toster toster-hidden"}>
          <div className="toster-title">
            {activeData ? <p>Are you sure you want to active {activeData?.accountData && activeData?.accountData[0]?.businessName ? activeData?.accountData[0]?.businessName: activeData?.Name} account?</p> :<p>Are you sure you want to delete a { deleteData?.accountData && deleteData?.accountData[0]?.businessName ? deleteData?.accountData[0]?.businessName : deleteData?.Name} from database?</p>}
          </div>
          <div className="toster-footer">
            <button onClick={() => toggle()}>Cancel</button>
            {activeData ? 
            <button onClick={() => handlerDelete()} className={"green-button-style"}>
            <span style={{ paddingLeft: "8px" }}>Active</span>
          </button>:
            <button onClick={() => handlerDelete()} className={"red-button-style"} >
                <img src={DeleteIcon} alt="DeleteIcon" />
                <span style={{ paddingLeft: "8px" }}>Delete</span>
              </button>}
          </div>
        </div>
      </div>
    </>
  );
}
