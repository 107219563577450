import React, { useRef, useState } from "react";
import "./activeStaffChart.scss";
import DownIcon from "../../../assets/icons/down-blue.svg";
import { Bar } from "react-chartjs-2";
import { chartDataset, chartOptions } from "../commonChartOptions";

export default function ActiveStaffChart(props) {
  const { selectedUsers, allChartData, isLive } = props;
  const chartRef = useRef();
  let allChartDataFinal = allChartData;
  if (isLive) {
    allChartDataFinal = allChartData.filter((data) => !!data?.activeStaff);
  }
  let chartDatasetOptions = chartDataset;
  // allChartDataFinal.length < 20 ? { ...chartDataset, barThickness: 30 } : chartDataset ;

  const handleChartClick = (e) => {
    // var activeElement = chartRef.current.getElementAtEvent(e);
    console.log("activeElement", e);
  };

  const [serviceDropdown, setServiceDropdown] = useState(false);
  const salesDropDownOptions = ["Total sales vs all", "Total sales vs staff revenue", "Total sales vs collection"];

  const state = {
    labels: allChartDataFinal?.map((data) => (!data?.acronym ? data?.businessName : data?.acronym || "")),
    datasets: [
      {
        ...chartDatasetOptions,
        label: "ActiveStaff",
        backgroundColor: "#C4DEFF",
        data: allChartDataFinal?.map((data) => data?.activeStaff),
      },
    ],
  };

  let options = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      tooltip: {
        ...chartOptions.plugins.tooltip,
        callbacks: {
          title: function (context) {
            let title = context[0]?.label;
            if (context[0].dataIndex) {
              title = allChartDataFinal[context[0].dataIndex]?.businessName;
            }
            return title;
          },
        },
      },
    },
    onClick: handleChartClick,
  };

  // chart_config.data.datasets[activeElement[0]._datasetIndex].data[activeElement[0]._index];
  return (
    <div>
      <div className="invoice-generated-chart-box-content-box">
        <div className="box-header">
          <div>
            <span>
              {allChartDataFinal?.reduce((a, b) => +a + +b.activeStaff, 0)} Number of active staff ({allChartDataFinal?.length})
            </span>
          </div>
          <div className="flex-items-sec">
            {/* <div className="sales-relative">
              <button onClick={() => setServiceDropdown(!serviceDropdown)}>
                <span>Total sales vs all</span>
                <img src={DownIcon} alt="DownIcon" />
              </button>
              <div className={serviceDropdown ? "service-dropdown service-dropdown-show" : "service-dropdown service-dropdown-hidden"}>
                <div>
                  {salesDropDownOptions.map((option) => (
                    <span>{option}</span>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="box-body-content-alignment">
          <div className="grid">
            <div className="grid-items"></div>
            <div className="grid-items">
              <div className="chart-items">
                <div className="staff-chart-container">
                  <Bar data={state} options={options} ref={chartRef} />
                </div>
              </div>
            </div>
          </div>
          <div className="empty-sapce"></div>
        </div>
      </div>
    </div>
  );
}
