import React, { useEffect, useState } from "react";
import "./accountManagers.scss";
import CloseIcon from "../../../assets/icons/new-close.svg";
import EditIcon from "../../../assets/icons/Edit.svg";
import RemoveIcon from "../../../assets/icons/remove-new-icon.svg";
import UseremoveIcon from "../../../assets/icons/user.svg";
import { ApiGet } from "../../../helpers/API/ApiData";
import AddNewManager from "../AddNewManager";
import UserInformationModal from "../userInformationModal";
import Success from "../../Toaster/Success/Success";
import Delete from "../../Toaster/Delete";

export default function AccountManagers(props) {
  const { setAccountManagersModal, accountManagersModal } = props;

  const [newAccountManager, setNewAccountManager] = useState(false);
  const [addValidityModal, setAddValidityModal] = useState(false);
  const [accountUpgradeModal, setAccountUpgradeModal] = useState(false);
  const [managerDetails, setManagerDetails] = useState([]);
  const [er, setEr] = useState();
  const [success, setSuccess] = useState(false);
  const [toastmsg, setToastmsg] = useState();
  const [editData, setEditdata] = useState();
  const [deleteData, setDeleteData] = useState();
  const [activeData, setActiveData] = useState();
  const [openDeletemodal, setOpenDeletemodal] = useState(false);

  useEffect(() => {
    gettingManagerDetails();
  }, []);

  const gettingManagerDetails = async () => {
    await ApiGet("account/manager/getAllmanager")
      .then((res) => {
        setManagerDetails(res?.data?.data.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const OpenAddManagermodal = (data) => {
    setNewAccountManager(!newAccountManager);
    setEditdata();
    if (data === true) {
      setSuccess(true);
      setToastmsg("New Manager Added!");
      gettingManagerDetails();
    }
    if (data === "edit") {
      setSuccess(true);
      setToastmsg("Updated Successfully!");
      gettingManagerDetails();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setEr();
    }, 1500);
    return () => clearTimeout(timer);
  });

  const EditManagermodal = (data) => {
    setEditdata(data?.data);
    setNewAccountManager(!newAccountManager);
  };

  const DeleteManagermodal = (data) => {
    setDeleteData(data?.data);
    setOpenDeletemodal(!openDeletemodal);
    if (data === true) {
      setSuccess(true);
      setToastmsg("Manager Deleted!");
      gettingManagerDetails();
    }
  };

  const ActiveUsermodal = (data) => {
    setActiveData(data?.data);
    setOpenDeletemodal(!openDeletemodal);
    if (data === true) {
      setSuccess(true);
      setToastmsg("Manager activated!");
      gettingManagerDetails();
    }
  };

  return (
    <>
      <div className="account-modal-manager-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => setAccountManagersModal(!accountManagersModal)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Account managers</h2>
          </div>
          <div className="blue-button" onClick={() => OpenAddManagermodal()}>
            <button>Add New</button>
          </div>
        </div>
        <div className="full-page-body-top-alignment">
          <div className="all-content-alignment">
            <div className="account-modal-width">
              <div className="sub-header-modal-title">
                <h5>Total number of account managers - {managerDetails?.length} </h5>
              </div>
              <div className="account-manager-table-design">
                <table>
                  <tr>
                    <th align="left">#</th>
                    <th align="left">Full name</th>
                    <th align="left">Mobile number</th>
                    <th align="left">Accounts</th>
                    <th align="left">Email id</th>
                    <th align="left">Password</th>
                  </tr>
                  {managerDetails.map((elem, i) => {
                    return (
                      <tr>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{i + 1}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.data?.Name}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.data?.mobileNumber}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.count} salons</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{elem?.data?.email}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="password-icon-alignment">
                            <div>
                              <span>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((ele) => {
                                  if (ele <= elem?.data?.password?.length) {
                                    return "*";
                                  }
                                })}
                              </span>
                            </div>
                            <div>
                              {elem?.data?.isActive ? (
                                <>
                                  <img src={EditIcon} alt="EditIcon" onClick={() => EditManagermodal(elem)} />
                                  <img src={RemoveIcon} alt="RemoveIcon" onClick={() => DeleteManagermodal(elem)} />
                                </>
                              ) : (
                                <img style={{ width: "18px" }} src={UseremoveIcon} alt="UseremoveIcon" onClick={() => ActiveUsermodal(elem)}/>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {newAccountManager && <AddNewManager toggle={OpenAddManagermodal} editData={editData} />}
      {success && <Success modal={success} toastmsg={toastmsg} />}
      {openDeletemodal && <Delete toggle={activeData ? ActiveUsermodal :DeleteManagermodal} deleteData={deleteData}  activeData={activeData}/>}
    </>
  );
}
