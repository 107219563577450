import React, { useState } from "react";
import "./login.scss";
import BarberaLogo from "../../assets/logo/barbera-logo.svg";
import EyeIcon from "../../assets/icons/eye-off.svg";
import EyeOnIcon from "../../assets/icons/eye-gray.svg";
import { useHistory } from "react-router-dom";
import { ApiPost } from "../../helpers/API/ApiData";
import * as userUtil from "../../utils/user.util";

export default function Login() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [inputRed, setInputRed] = useState(false);
  const [password, setPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formstatus, setFormstatus] = useState("");

  const handleLoginwithEmail = async (setStatus) => {
    const data = {
      email: email,
      password: password,
    };
    await ApiPost("account/validateAccount", data)
      .then(async (res) => {
        if (res.data.status === 200) {
          setLoading(false);
          if (res?.data?.data[0]?.role === "SuperAdmin" || res?.data?.data[0]?.role === "Manager"  ) {
            localStorage.clear();
            localStorage.setItem("ManagerId", res?.data?.data[0]?._id);
            let userInfo = res?.data?.data?.map((user) => {
              return { ...user, password: "******" };
            });
            userUtil.setUserInfo(userInfo[0]);
            history.replace("/");
          } else {
            setLoading(false);
            setFormstatus(" * Invalid Credentials");
          }
        } else {
          setLoading(false);
          setFormstatus(" * Invalid Credentials");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setFormstatus(" * Invalid Credentials");
        } else {
          setFormstatus(" * Something Went Wrong !");
        }
      });
  };

  return (
    <>
      <div className="login-box-center-alignment">
        <div className="container">
          <div className="box-center-alignment">
            <div className="login-box">
              <div className="logo-center-alignment">
                <img src={BarberaLogo} alt="BarberaLogo" />
              </div>
              <div className="login-text-style">
                <p>Welcome back to Barbera Admin!</p>
                <span>Enter credentials to access your account</span>
              </div>
              <div className="input">
                <label>
                  Enter email
                  <span
                    style={{
                      color: "#E66666",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {formstatus === "" ? "" : formstatus}
                  </span>
                </label>{" "}
                <input
                  type="text"
                  style={{ border: inputRed && "1px solid red" }}
                  placeholder="e.g. admin@barbera.com"
                  value={email}
                  autoFocus
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setInputRed(false);
                  }}
                />
              </div>
              <div className="input">
                <label>Password</label>
                <div className="relative-div">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="icon-alignment">
                    <img
                      src={showConfirmPassword ? EyeIcon : EyeOnIcon}
                      alt="EyeIcon"
                      onClick={(e) =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className="login-button"
                onClick={() => handleLoginwithEmail()}
              >
                <button>Log in</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
