import moment from "moment";

export const bindInput = (value) => {
  var regex = new RegExp("^[^0-9]*$");
  var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
  if (value?.code === "Backspace" || value?.code === "Tab" || value?.code === "Backspace" || value?.code === "Delete") return;
  if (regex.test(key)) {
    value.preventDefault();
    return false;
  }
};

export const generateMonthLablesByNumberOfMonths = (numberOfMonths) => {
  let tempLabels = [];
  for (let i = 0; i < numberOfMonths; i++) {
    let thisMonthDate = moment().subtract(i, "months");
    let monthYear = moment(thisMonthDate).format("MMM YY");
    tempLabels.push(monthYear);
  }
  tempLabels.reverse();
  return tempLabels;
};


export const sumAndSeggerate = (data) => {
  if(!data ) return 0 ;

  return data?.reduce((previousItem , currentItem) => {
    const  {amount , currency} = currentItem ;
    if(!previousItem[currency]) {
      previousItem[currency] = 0 ; 
    }

    previousItem[currency] += parseInt(amount)

    return previousItem;
  } , {})
}