import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    value: [],
  },
  reducers: {
    setUsersStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUsersStore } = usersSlice.actions;

export default usersSlice.reducer;
