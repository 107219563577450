import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/icons/new-close.svg";
import { ApiGet } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";
import moment, { fn } from "moment";
import "../couponCode/couponCode.scss";

export default function PaymentListModal(props) {
  const {handlePaymentData} = props;
const [paymentList,setPayementList]=useState([])
  const userInfo = Auth.getUserDetail();

  useEffect(async()=>{
    await ApiGet("campaignPayment")
        .then((res) => {
          console.log("campaignPayment", res?.data?.data);
          setPayementList((res?.data?.data).reverse());
        })
        .catch((err) => {
          console.log(err);
        });
  },[])

  return (
    <div className="account-modal-manager-wrapper">
      <div className="full-page-header-alignment">
        <div className="header-title">
          <div onClick={() => handlePaymentData()}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
          <h2>Coupon code</h2>
        </div>
      </div>
      
      <div className="full-page-body-top-alignment">
        <div className="all-content-alignment">
          <div className="coupon-modal-width">
            <div className="account-manager-table-design">
            <h5>Total Amount  - { paymentList.map((item) => parseInt(item.amount)).reduce((prev, curr) => prev + curr, 0)} </h5>
              <table>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Generated by</th>
                  <th align="left">PaymentType</th>
                  <th align="left">PaymentFor</th>
                  <th align="left">Amount</th>
             
                </tr>
                {paymentList?.map((list) => {
                      return (
                        <tr>
                          <td align="left">
                            <div className="normal-text-style">
                              <span>{moment(list?.created).format("DD MMM ‘YY")}</span>
                            </div>
                          </td>
                          <td align="left">
                            <div className="normal-text-style">
                              <span>{list?.companyId}</span>
                            </div>
                          </td>
                          <td align="left" >
                            <div className="normal-text-style">
                              <span >{list?.paymentGatewayId}</span>
                            </div>
                          </td>
                          <td align="left" >
                            <div className="normal-text-style">
                              <span >{list?.type}</span>
                            </div>
                          </td>
                          <td align="left">
                            <div className="normal-text-style">
                              <span>
                                {list?.amount}
                              
                              </span>
                            </div>
                          </td>
                          
                        </tr>
                      );
                    })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
