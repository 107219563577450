import React, { useState, useEffect } from "react";
import "./AddUserModal.scss";
import CloseIcon from "../../../assets/icons/new-close.svg";
import SelectInput from "../../selectInput";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import EyeIcon from "../../../assets/icons/eye-off.svg";
import EyeOnIcon from "../../../assets/icons/eye-gray.svg";
import Auth from "../../../helpers/Auth";
import moment from "moment";
import Success from "../../Toaster/Success/Success";

export default function AddUserModal(props) {
  const { toggle, editUSerData, EdituserModal } = props;
  let userInfo = Auth.getUserDetail();
  const StatusArray = [{ Name: "Unpaid" }, { Name: "Paid" }];
  const [disable, setDisable] = useState(true);
  const [managerDetails, setManagerDetails] = useState([]);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [planFeture, setPlanFeture] = useState([]);
  const [accountData, setAccountData] = useState({
    nameOfSalonOwner: "",
    mobileNumber: "",
    businessName: "",
    acronym: "",
    email: "",
    address: "",
    password: "",
  });
  const [validity, setValidity] = useState();
  const [status, setStatus] = useState({ Name: "Unpaid" });
  const [manager, setManager] = useState(userInfo);
  const [FinalPrice, setFinalPrice] = useState();
  const [success, setSuccess] = useState(false);
  const [toastmsg, setToastmsg] = useState();
  const [er, setEr] = useState();
  const [errors, setError] = useState({});
  const [invoiceNo, setInvoiceNo] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [featuresListJson, setFeaturesListJson] = useState([
    { name: "Appointments", slug: "appointment", isChecked: true, type: "basic" },
    { name: "Invoices", slug: "invoice", isChecked: true, type: "basic" },
    { name: "Inventory", slug: "inventory", isChecked: true, type: "basic" },
    { name: "Customer profile", slug: "customerProfile", isChecked: true, type: "basic" },
    { name: "Customer wallet", slug: "customerWallet", isChecked: true, type: "basic" },
    { name: "Staff profile", slug: "staffProfile", isChecked: true, type: "basic" },
    { name: "Staff attendance", slug: "staff", isChecked: true, type: "basic" },
    { name: "SMS, Notifications", slug: "sms", isChecked: true, type: "basic" },
    { name: "Statement, Daily reports", slug: "statement", isChecked: true, type: "basic" },
    { name: "Product consumption", slug: "product", isChecked: true, type: "basic" },
    { name: "Expenses", slug: "expenses", isChecked: true, type: "basic" },
    { name: "SMS promotion", slug: "smsPromotion", isChecked: true, type: "basic" },
    { name: "Online booking app", slug: "onlineBookingApp", isChecked: true, type: "basic" },
    { name: "Service reminders", slug: "serviceReminder", isChecked: true, type: "basic" },

    { name: "Membership", slug: "membership", isChecked: true, type: "professional" },
    { name: "Staff commission", slug: "staffCommission", isChecked: true, type: "professional" },
    { name: "Staff pay", slug: "staffPayroll", isChecked: true, type: "professional" },
    { name: "Ledger/cash register", slug: "leager", isChecked: true, type: "professional" },
    { name: "Offers", slug: "offers", isChecked: true, type: "professional" },
    { name: "Vouchers", slug: "vouchers", isChecked: true, type: "professional" },
    { name: "Package", slug: "package", isChecked: true, type: "professional" },
    { name: "Vendors management", slug: "vendorsManagement", isChecked: true, type: "professional" },
    { name: "Purchase orders", slug: "purchaseOrders", isChecked: true, type: "professional" },
    { name: "Enquiry", slug: "enquiry", isChecked: true, type: "professional" },
    { name: "Roles & Permissions", slug: "rolesPermission", isChecked: true, type: "professional" },
    { name: "Business analytics", slug: "businessAnalytics", isChecked: true, type: "professional" },

    { name: "Multiple outlet management", slug: "outlets", isChecked: true, type: "enterprise" },
    { name: "Outlet admin", slug: "outletsAdmin", isChecked: true, type: "enterprise" },
    { name: "Customized packages", slug: "customizedPackages", isChecked: true, type: "enterprise" },
    { name: "Staff performance dashboard", slug: "staffPerformanceDashboard", isChecked: true, type: "enterprise" },
    { name: "Biometric staff attendance", slug: "biometricStaffAttendance", isChecked: true, type: "enterprise" },
    { name: "Reward programs", slug: "rewardPrograms", isChecked: true, type: "enterprise" },
  ]);
  useEffect(() => {
    if (
      accountData?.nameOfSalonOwner &&
      accountData?.mobileNumber &&
      accountData?.businessName &&
      accountData?.email &&
      accountData?.address &&
      accountData?.password &&
      status &&
      manager &&
      validity
    ) {
      if (status?.Name === "Paid" && (!FinalPrice || !invoiceNo)) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [accountData, status, manager, validity, FinalPrice, invoiceNo]);

  useEffect(() => {
    gettingManagerDetails();
  }, []);

  useEffect(async () => {
    if (editUSerData) {
      setAccountData({
        nameOfSalonOwner: editUSerData?.nameOfSalonOwner,
        mobileNumber: editUSerData?.mobileNumber,
        businessName: editUSerData?.businessName,
        acronym: editUSerData?.acronym || "",
        email: editUSerData?.email,
        address: editUSerData?.address,
        password: editUSerData?.password,
      });
    }

    if (editUSerData?.parentId) {
      await ApiGet(`account/${editUSerData?.parentId}`)
        .then((res) => {
          setManager(res.data.data[0]);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    setDisable(true);
    setValidity(7);
  }, [editUSerData]);

  useEffect(async () => {
    let resp = await ApiGet("monetizePlan");
    if (resp.data.status === 200) {
      setPlanFeture(resp.data.data);
      setSelectedPlan(resp.data.data[0]);
    }
  }, []);

  const handleOnChangeCapital = (e) => {
    setDisable(true);
    let { name, value } = e.target;
    const toTitleCase = (str) => str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
    setAccountData((prevState) => {
      return {
        ...prevState,
        [name]: toTitleCase(e.target.value),
      };
    });
  };

  const handleOnChange = (e) => {
    setDisable(true);
    let { name, value } = e.target;
    setAccountData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const gettingManagerDetails = async () => {
    await ApiGet("account/manager/getAllmanager")
      .then((res) => {
        // Admin added for select manager
        let ActiveManager = res?.data?.data?.filter((obj) => obj.data.isActive);
        let managers = ActiveManager?.map((user) => {
          return user?.data;
        });
        let finalData = [userInfo].concat(managers);
        setManagerDetails(finalData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!accountData?.businessName) {
      formIsValid = false;
      errors["businessName"] = "* Enter valid input";
    }
    if (!accountData?.nameOfSalonOwner) {
      formIsValid = false;
      errors["fullname"] = "* Enter valid input";
    }
    if (accountData?.nameOfSalonOwner?.trim() == "") {
      formIsValid = false;
      errors["fullname"] = "* Enter valid input";
    }

    if (accountData?.mobileNumber?.trim() == "") {
      formIsValid = false;
      errors["mobilenumber"] = "* Enter valid Mobile number";
    }
    if (accountData?.mobileNumber.length < 10) {
      formIsValid = false;
      errors["mobilenumber"] = "* Enter valid Mobile number";
    }
    if (accountData?.mobileNumber.length > 10) {
      formIsValid = false;
      errors["mobilenumber"] = "* Enter valid Mobile number";
    }
    if (!EMAIL_REGEX.test(accountData?.email)) {
      formIsValid = false;
      errors["email"] = "* Enter valid input";
    }
    if (!accountData?.password) {
      formIsValid = false;
      errors["password"] = "* Enter valid input";
    }
    setError(errors);
    return formIsValid;
  };
  const colorList = ["#FFD5C2", "#FFD3AA", "#FEFFC2", "#CBF9FF", "#D1FFF4", "#C2FFC8", "#F5CBFF", "#FFD8FD", "#FBDEE3", "#FFD6E3"];

  const handleEditUser = async () => {
    // if (validate()) {
    let payload = {
      password: accountData?.password,
      mobileNumber: accountData?.mobileNumber,
      businessName: accountData?.businessName,
      acronym: accountData?.acronym?.toUpperCase() || "",
      Name: accountData?.nameOfSalonOwner,
      firstName: accountData?.nameOfSalonOwner,
      nameOfSalonOwner: accountData?.nameOfSalonOwner,
      email: accountData?.email,
      address: accountData?.address,
      parentId: manager._id,
    };

    try {
      let res = await ApiPut(`account/${editUSerData?._id}`, payload);
      if (res.data.status === 200) {
        EdituserModal(true);
      } else {
        setSuccess(true);
        setEr("Error");
        setToastmsg("User already exists!");
      }
    } catch (err) {
      setSuccess(true);
      setEr("Error");
      setToastmsg("Something went wrong!");
    }
    // }
  };
  const updateFeatureData = async (selectedPlanName, companyId) => {
    let thisPlanServicesList = featuresListJson?.reduce((a, b) => {
      return selectedPlanName === "Basic"
        ? b?.type === "basic"
          ? { ...a, [b?.slug]: true }
          : { ...a, [b?.slug]: false }
          : selectedPlanName === "Pro"
          ? b?.type === "professional" || b?.type === "basic"
            ? { ...a, [b?.slug]: true }
            : { ...a, [b?.slug]: false }
        : { ...a, [b?.slug]: true };
    }, {});

    let payload = { ...thisPlanServicesList, companyId: companyId };

    try {
      let updateFeaturesRes = await ApiPost("features", payload);
      if (updateFeaturesRes?.data?.status === 200) {
        console.log("Features updated successfully");
      } else {
        console.log(updateFeaturesRes.data.message);
      }
    } catch {
      console.log("Error updating features");
    }
  };

  const handleAddUser = async () => {
    if (validate()) {
      let payload = {
        role: "Admin",
        password: accountData?.password,
        mobileNumber: accountData?.mobileNumber,
        acronym: accountData?.acronym?.toUpperCase() || "",
        businessName: accountData?.businessName,
        Name: accountData?.nameOfSalonOwner,
        firstName: accountData?.nameOfSalonOwner,
        nameOfSalonOwner: accountData?.nameOfSalonOwner,
        email: accountData?.email,
        address: accountData?.address,
        parentId: manager._id,
      };

      try {
        let res = await ApiPost("account/", payload);
        if (res.data.status === 200) {
          getAccountInfo(res.data.data._id);
        } else {
          setSuccess(true);
          setEr("Error");
          setToastmsg("User already exists!");
        }
      } catch (err) {
        setSuccess(true);
        setEr("Error");
        setToastmsg("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setEr();
    }, 1500);
    return () => clearTimeout(timer);
  });

  const getAccountInfo = async (id) => {
    try {
      let res = await ApiGet("account/" + id);
      if (res.data.status === 200) {
        //create default payment method
        for (var i = 0; i < 3; i++) {
          createPaymentMethod(res?.data?.data[0]?.companyId, i === 0 ? "Cash" : i == 1 ? "Debit/Credit Card" : "UPI", i == 0 ? true : false);
        }
        //add data for defult whatsup sms
        updateDailyWhatsup(res?.data?.data[0]);

        //add manager Revenue entry
        if (status?.Name === "Paid") {
          addRevenueDetails(res?.data?.data[0]);
        }

        //add default trial days
        trialDays(res?.data?.data[0]?.companyId);

        //create default setting
        setOnobardingData(res?.data?.data[0]?.companyId);

        //create default staff
        createStaff(res?.data?.data[0]?.companyId);

        //create default category
        createCategory(res?.data?.data[0]?.companyId);

        //create default color panel
        colorList.forEach((element) => {
          createColor(res?.data?.data[0]?.companyId, element);
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const addRevenueDetails = async (userData) => {
    if (status?.Name === "Paid") {
      let revenueDetail = {
        date: new Date(),
        salonName: userData,
        accountManagerId: manager._id,
        validity: validity,
        amount: FinalPrice,
      };
      await ApiPost("managerRevenue", revenueDetail)
        .then(async (res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const trialDays = async (companyId) => {
    let paymentID;
    if (status?.Name === "Paid") {
      let bodyy = {
        paymentGatewayId: "Cash",
        amount: FinalPrice,
        companyId: companyId,
        type: "Payment",
      };
      await ApiPost("campaignPayment", bodyy)
        .then(async (res) => {
          paymentID = res?.data?.data?._id;
          let planpayment = [
            {
              planId: selectedPlan,
              planBasePrice: parseInt(FinalPrice / (1 + 18 / 100)),
              planGstAmount: FinalPrice - parseInt(FinalPrice / (1 + 18 / 100)),
              paymentAmount: FinalPrice,
              paymentStartDate: moment.utc(new Date()).format(),
              paymentEndDate: moment.utc(new Date()).add(validity, "days").format(),
              isActive: true,
              companyId: companyId,
              razorpaymentId: paymentID ? paymentID : "Cash",
              paymentId: invoiceNo,
            },
          ];
          let PlanDetail = {
            finalDate: moment.utc(new Date()).add(validity, "days").format(),
            paymentData: status?.Name === "Unpaid" ? [] : planpayment,
            companyId: companyId,
          };
          let resp = await ApiPost("monetize", PlanDetail);
          if (resp.data.status === 200) {
            updateFeatureData(selectedPlan?.planName, companyId);
            toggle(true);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      let planpayment = [
        {
          planId: status?.Name === "Unpaid" ? planFeture[2] : selectedPlan,
          planBasePrice: parseInt(FinalPrice / (1 + 18 / 100)),
          planGstAmount: FinalPrice - parseInt(FinalPrice / (1 + 18 / 100)),
          paymentAmount: FinalPrice,
          paymentStartDate: moment.utc(new Date()).format(),
          paymentEndDate: moment.utc(new Date()).add(validity, "days").format(),
          isActive: true,
          companyId: companyId,
          razorpaymentId: paymentID ? paymentID : "Cash",
        },
      ];
      let PlanDetail = {
        finalDate: moment.utc(new Date()).add(validity, "days").format(),
        paymentData: status?.Name === "Unpaid" ? [] : planpayment,
        companyId: companyId,
      };
      let resp = await ApiPost("monetize", PlanDetail);
      if (resp.data.status === 200) {
        updateFeatureData(status?.Name === "Unpaid" ? planFeture[2]?.planName : selectedPlan?.planName, companyId);
        toggle(true);
      }
    }
  };
  const defaultData = [
    [
      "Sales",
      [
        "Total sales",
        "Number of generated invoices",
        "Number of products sold",
        "Average ticket size",
        "Discount offered & Tax",
        "Due amount & Due invoices",
      ],
    ],
    ["Appointments", ["Total appointments", "Total services availed", "Popular hours"]],
    [
      "Collections",
      ["Total sales collections", "Opening collection", "Closing collection", "Expense & Staff pay", "Receive from Owner", "Transfer to Owner"],
    ],
    ["Staff", ["Staff wise sales", "Staff performance"]],
    [
      "Customers",
      [
        "Available wallet balance",
        "Wallet top-ups",
        "Wallet redeemed",
        "Wallet Withdrawals",
        "Previous due paid",
        "Customer reviews",
        "Custmomer visits",
        "Number of customers added to the system",
      ],
    ],
  ];

  const updateDailyWhatsup = async (user) => {
    let updated = {
      permission: defaultData,
      user: [{ contactName: user?.Name, contactNumber: user?.mobileNumber }],
      time: moment("23:00", "hh-mm a")._d,
      companyId: user?.companyId,
      featureOn: true,
    };
    await ApiPost("statement", updated)
      .then(async (res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const setOnobardingData = async (companyId) => {
    let onboardingInitData = {
      firstDay: "Sunday",
      bookingInterval: "15",
      storeTiming: [
        {
          isStoreClosed: false,
          day: "Sunday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Monday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Tuesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Wednesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Thursday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Friday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Saturday",
          starttime: "10:00",
          endtime: "20:00",
        },
      ],
      multipleStaff: { assignMultipleStaff: true },
      attendence: { attendanceToggle: true },
      workingDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      customer: false,
      iCustomer: false,
      appointments: true,
      service: true,
      staff: true,
      serviceTime: "15",
      companyId: companyId,
      inventory: {
        enableInventory: true,
        productType: "Store Consumable & Retail",
        enableBarcode: false,
      },
      tax: {
        gstCharge: false,
        gstNumber: "",
        serviceTax: false,
        serviceTaxPer: "CGST 9%, SGST 9%",
        productTax: false,
        productTaxPer: [],
      },
      paymentMethod: ["Cash", "Debit/Credit Card", "UPI"],
      onboardProcess: [
        {
          onboardingStatus: null,
          onboardingCompleted: false,
          onboardingTourProgress: 0,
          onboardingProfileUpdated: false,
        },
      ],
      membership: {
        membership: true,
        membershipBenefits: "Free & Discounted services",
        applyMembershipBenefits: true,
      },
      firstReminderDate: new Date(),
      secondReminderDate: new Date(),
      thirdReminderDate: new Date(),
      currentType: "₹",
    };
    await ApiPost("setting/", onboardingInitData)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const createStaff = async (companyId) => {
    let body = {
      firstName: "Unassign",
      lastName: "",
      mobileNumber: "9898975574",
      workingDays: [
        {
          isStoreClosed: false,
          Day: "Sunday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Monday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Tuesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Wednesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Thursday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Friday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Saturday",
          starttime: "10:00",
          endtime: "20:00",
        },
      ],
      companyId: companyId,
      default: true,
    };
    await ApiPost("staff/", body);
  };

  const createCategory = async (companyId) => {
    let body1 = {
      categoryName: "Unassign",
      categoryColor: "#D1FFF4",
      companyId: companyId,
    };
    let body2 = {
      categoryName: "Hair Treatment",
      categoryColor: "#FFD3AA",
      companyId: companyId,
      staff: [],
      isActive: true,
    };
    let resp = await ApiPost("category/", body1);
    try {
      if (resp.data.status === 200) {
        //create default services
        createDefaultService(resp.data.data._id, companyId);
        let res = await ApiPost("category/", body2);
        try {
          if (res.data.status === 200) {
            //create default services
            createServices(res.data.data._id, companyId);
          }
        } catch (er) {
          console.log(er);
        }
      }
    } catch (er) {
      console.log(er);
    }
  };

  const createColor = async (companyId, color) => {
    let payload = {
      name: color,
      companyId: companyId,
    };
    await ApiPost("colour/", payload);
  };

  const createDefaultService = async (categoryId, companyId) => {
    const body = {
      categoryId: categoryId,
      serviceName: "Slot",
      duration: "30",
      amount: "0",
      companyId: companyId,
      default: true,
    };
    await ApiPost("service/", body);
  };

  const createServices = async (categoryId, companyId) => {
    const body1 = {
      categoryId: categoryId,
      serviceName: "Hair Wash",
      duration: 15,
      amount: 150,
      tax: {},
      companyId: companyId,
      productConsumptions: [],
      isActive: true,
    };
    const body2 = {
      categoryId: categoryId,
      serviceName: "Hair Cut",
      duration: 40,
      amount: 500,
      tax: {},
      companyId: companyId,
      productConsumptions: [],
      isActive: true,
    };
    await ApiPost("service/", body1);
    await ApiPost("service/", body2);
  };

  const createPaymentMethod = async (companyId, method, status) => {
    let payload = {
      paymentType: method,
      companyId: companyId,
      default: status,
    };
    await ApiPost("payment", payload);
  };

  return (
    <>
      <div className="full-page-modal-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => toggle()}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2> {editUSerData ? "Edit user" : "Add new user"}</h2>
          </div>

          {disable ? (
            editUSerData ? (
              <div className="blue-button" onClick={() => handleEditUser()}>
                <button>Edit User</button>
              </div>
            ) : accountData?.nameOfSalonOwner &&
              accountData?.mobileNumber &&
              accountData?.businessName &&
              accountData?.email &&
              accountData?.address &&
              accountData?.password &&
              status &&
              manager &&
              validity ? (
              <div className="blue-button" onClick={() => handleAddUser()}>
                <button>Add User</button>
              </div>
            ) : editUSerData ? (
              <div className="blue-button">
                <button style={{ opacity: "0.5" }}>Edit User</button>
              </div>
            ) : (
              <div className="blue-button">
                <button style={{ opacity: "0.5" }}>Add User</button>
              </div>
            )
          ) : editUSerData ? (
            <div className="blue-button" onClick={() => handleEditUser()}>
              <button>Edit User</button>
            </div>
          ) : (
            <div className="blue-button" onClick={() => handleAddUser()}>
              <button>Add User</button>
            </div>
          )}
        </div>
        <div className="full-page-body-top-alignment">
          <div className="container">
            <div className="box-center-alignment">
              <div className="account-data-box">
                <div className="box-title">
                  <p>Account data</p>
                </div>
                <div className="box-body-alignment">
                  <div className="input">
                    <label>
                      Salon name{" "}
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["businessName"]}{" "}
                      </span>
                    </label>{" "}
                    <input
                      type="text"
                      name="businessName"
                      value={accountData?.businessName}
                      placeholder="e.g. Gada Hair salon"
                      onChange={(e) => handleOnChangeCapital(e)}
                    />
                  </div>
                  {userInfo?.role === "SuperAdmin" && (
                    <div className="input">
                      <label>
                        Acronym{" "}
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "10px",
                          }}
                        >
                          {" "}
                          {errors["acronym"]}{" "}
                        </span>
                      </label>{" "}
                      <input
                        type="text"
                        name="acronym"
                        value={accountData?.acronym?.toUpperCase()}
                        maxLength={7}
                        placeholder="e.g. GHS"
                        onChange={(e) => handleOnChangeCapital(e)}
                      />
                    </div>
                  )}
                  <div className="input">
                    <label>
                      Salon owner name{" "}
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["fullname"]}{" "}
                      </span>
                    </label>{" "}
                    <input
                      type="text"
                      name="nameOfSalonOwner"
                      value={accountData?.nameOfSalonOwner}
                      placeholder="e.g. Jethalal Gada"
                      onChange={(e) => handleOnChangeCapital(e)}
                    />
                  </div>
                  <div className="input">
                    <label>
                      Mobile number
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["mobilenumber"]}{" "}
                      </span>
                    </label>{" "}
                    <input
                      type="number"
                      name="mobileNumber"
                      maxLength="10"
                      value={accountData?.mobileNumber}
                      placeholder="12345 67890"
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div className="input">
                    <label>
                      Email{" "}
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["email"]}{" "}
                      </span>
                    </label>{" "}
                    <input
                      type="text"
                      name="email"
                      value={accountData?.email}
                      placeholder="e.g. gadasalon@gmail.com"
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div className="input">
                    <label>
                      Password
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["password"]}{" "}
                      </span>
                    </label>{" "}
                    <div className="relative-div">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        name="password"
                        value={accountData?.password}
                        placeholder=""
                        onChange={(e) => handleOnChange(e)}
                      />
                      <div className="icon-alignment">
                        <img
                          src={showConfirmPassword ? EyeIcon : EyeOnIcon}
                          alt="EyeIcon"
                          onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <label>Salon address</label>
                    <textarea
                      placeholder="Enter address"
                      name="address"
                      value={accountData?.address}
                      onChange={(e) => handleOnChangeCapital(e)}
                    ></textarea>
                  </div>
                  {!editUSerData && (
                    <div className="input">
                      <label>Account validity</label>
                      <div className="relative-div">
                        <input
                          type="number"
                          placeholder="15"
                          value={validity}
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => setValidity(e.target.value)}
                          onKeyPress={bindInput}
                        />
                        <div className="day-text-alignment">
                          <span>Days</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {userInfo?.role === "SuperAdmin" ? (
                    <SelectInput
                      name={"Account manager"}
                      value={manager?.Name}
                      setValue={setManager}
                      placeholder={"Select sales person"}
                      DropDown={managerDetails}
                    />
                  ) : (
                    <div className="input">
                      <label>Account manager</label> <input type="text" name="mobileNumber" disabled value={manager?.Name} />
                    </div>
                  )}
                  {!editUSerData && (
                    <>
                      <SelectInput name={"Status"} value={status.Name} setValue={setStatus} placeholder={"Paid or Unpaid"} DropDown={StatusArray} />
                      {status.Name === "Paid" && (
                        <>
                          <SelectInput
                            name={"Select Plan name"}
                            value={selectedPlan?.planName}
                            setValue={setSelectedPlan}
                            DropDown={planFeture}
                            isPlan={true}
                          />
                          {/* <div className="input">
                            <label>Selected Plan name</label>
                            <input type="text" name="planName" value={planFeture[0]?.planName} />
                          </div> */}
                          <div className="input">
                            <label>Invoice no.</label>
                            <input type="number" name="planName" value={invoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} />
                          </div>
                          <div className="input">
                            <label>Amount paid</label>
                            <div className="relative-div">
                              <input
                                type="number"
                                placeholder="15"
                                value={FinalPrice}
                                onChange={(e) => setFinalPrice(e.target.value)}
                                onWheel={() => document.activeElement.blur()}
                              />
                              <div className="day-text-alignment">
                                <span>
                                  <a>₹</a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {success && <Success modal={success} toastmsg={toastmsg} er={er} />}
    </>
  );
}
