export const commonLineChartOptions = {
  borderWidth: 3,
  offsetGridLines: false,
  drawTicks: true,
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0,
    },
    point: {
      display: false,
      backgroundColor: "transparent",
      borderColor: "transparent",
      hitRadius: 50,
      radius: 0,
    },
  },
  layout: {
    padding: {
      top: 40,
      left: 20,
      right: 40,
      bottom: 10,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    // tooltip: {
    //   backgroundColor: "#193566",
    //   titleColor: "#FFF",
    // },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        // display: false,
      },
      // offset: true,
      ticks: {
        // display: false,
        // padding: 1,
        major: {
          enabled: false,
        },
      },
    },
    y: {
      display: false,
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
        // padding: 1,
        major: {
          enabled: false,
        },
      },
    },
  },
};

export const chartDataset = {
  borderWidth: 0,
  categoryPercentage: 0.5,
  barPercentage: 1,
  borderRadius: 5,
};
