import React, { useEffect, useState } from "react";
import "./filterUserModal.scss";
import CloseIcon from "../../../assets/icons/new-close1.svg";
import SearchIcon from "../../../assets/icons/new-search.svg";
import CheckedIcon from "../../../assets/icons/Checked.svg";

export default function FilterUserModal(props) {
  const { toggle, allUsers, selectedUsers, setSelectedUsers,setActiveFilterBtn } = props;
  const [searchInput, setSearchInput] = useState("");
  const [tempAllUsers, setTempAllUsers] = useState(allUsers);
  const [tempSelectedUsers, setTempSelectedUsers] = useState(selectedUsers);

  const handleSelectUser = (e, user) => {
    if (tempSelectedUsers?.find((data) => data?.accountData[0]?._id === user?.accountData[0]?._id)) {
      setTempSelectedUsers(tempSelectedUsers?.filter((data) => data?.accountData[0]?._id !== user?.accountData[0]?._id));
    } else {
      setTempSelectedUsers([...tempSelectedUsers, user]);
    }
  };

  const handleApplyFilters = (e) => {
    e.preventDefault();
    if (props?.from !== "analytics"){
    setActiveFilterBtn("Selected Users")
    }
    setSelectedUsers(tempSelectedUsers);
    if (props?.from !== "analytics") localStorage.setItem("selectedUsers", JSON.stringify(tempSelectedUsers));
    toggle();
  };

  const handleToggleAll = () => {
    if (tempSelectedUsers?.length > 0) {
      setTempSelectedUsers([]);
    } else {
      setTempSelectedUsers(allUsers);
    }
  };

  useEffect(() => {
    if (searchInput === "") {
      setTempAllUsers(allUsers);
    } else {
      setTempAllUsers(allUsers?.filter((user) => user?.accountData[0]?.businessName?.toLowerCase()?.includes(searchInput?.toLowerCase())));
    }
  }, [searchInput]);

  return (
    <div>
      <div className="filter-user-modal-bluer">
        <div className="filter-user-modal-md">
          <div className="filter-user-modal-header">
            <div className="header-all-content-alignment">
              <div className="first-box-title-alignment">
                <div onClick={toggle}>
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <h2>Filters: Users</h2>
              </div>
              <div className="button-alignment">
                <span onClick={handleToggleAll}>{tempSelectedUsers?.length === 0 ? "Select all" : "Clear all"}</span>
                <button onClick={handleApplyFilters}>Apply filters</button>
              </div>
            </div>
          </div>
          <div className="filter-user-modal-body-content-alignment">
            <div>
              <div className="serch-relative">
                <input type="text" placeholder="Search users" onChange={(e) => setSearchInput(e.target.value)} />
                <div className="search-icon-alignment">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
              <div className="all-body-text-checkbox-content-alignment">
                {tempAllUsers.map((user, index) => {
                  let thisUserSelected = tempSelectedUsers?.find((data) => data?.accountData[0]?._id === user?.accountData[0]?._id);
                  return (
                    <div className={`card-box ${thisUserSelected && "card-box-selected"}`} key={index} onClick={(e) => handleSelectUser(e, user)}>
                      <span>{user?.accountData[0]?.businessName}</span>
                      {thisUserSelected && <img src={CheckedIcon} alt="CheckedIcon" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
