import React from "react";
import "./appointmentsChart.scss";
import { Bar } from "react-chartjs-2";
import { chartDataset, chartOptions } from "../commonChartOptions";
import LegendOptions from "../LegendOptions";

export default function AppointmentsChart(props) {
  const { selectedUsers, allChartData, isLive } = props;

  let allChartDataFinal = allChartData;
  if (isLive) {
    allChartDataFinal = allChartData.filter((data) => !!data?.cancel || !!data?.noShow || !!data?.finalComplete);
  }
  let chartDatasetOptions = chartDataset;
  // allChartDataFinal.length < 20 ? { ...chartDataset, barThickness: 30 } : chartDataset ;

  const state = {
    labels: allChartDataFinal?.map((data) => (!data?.acronym ? data?.businessName : data?.acronym || "")),
    datasets: [
      {
        ...chartDatasetOptions,
        label: "Cancelled",
        backgroundColor: "#F97477",
        data: allChartDataFinal?.map((data) => data?.cancel),
      },

      {
        ...chartDatasetOptions,
        label: "No - show",
        backgroundColor: "#93969E",
        data: allChartDataFinal?.map((data) => data?.noShow),
      },
      {
        ...chartDatasetOptions,
        label: "Completed",
        backgroundColor: "#FDB45C",
        data: allChartDataFinal?.map((data) => data?.finalComplete),
      },
    ],
  };

  const legendOptions = state?.datasets
    ?.map((data) => {
      return {
        name: data?.label,
        backgroundColor: data?.backgroundColor,
      };
    })
    .reverse();

  let options = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      tooltip: {
        ...chartOptions.plugins.tooltip,
        callbacks: {
          title: function (context) {
            let title = context[0]?.label;
            if (context[0].dataIndex) {
              title = allChartDataFinal[context[0].dataIndex]?.businessName;
            }
            return title;
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="invoice-generated-chart-box-content-box">
        <div className="box-header">
          <div>
            <span>
              {allChartDataFinal?.length === 0 ? 0 : allChartDataFinal?.reduce((a, b) => +a + +b.cancel + +b.noShow + +b.finalComplete, 0)}{" "}
              Appointments ({allChartDataFinal?.length})
            </span>
          </div>
          <LegendOptions legendOptions={legendOptions} />
        </div>
        <div className="box-body-content-alignment">
          <div className="grid">
            <div className="grid-items"></div>
            <div className="grid-items">
              <div className="chart-items">
                <div className="appointment-chart-container">
                  <Bar data={state} options={options} />
                </div>
              </div>
            </div>
          </div>
          <div className="empty-sapce"></div>
        </div>
      </div>
    </div>
  );
}
