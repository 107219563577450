import React from "react";
import "./LegendOptions.scss";

function LegendOptions(props) {
  const { legendOptions } = props;
  return (
    <div className="option-container">
      {legendOptions?.map((data) => {
        return (
          <div className="option">
            <span className="bullet" style={{ backgroundColor: data?.backgroundColor }}></span>
            <span className="name">{data?.name}</span>
          </div>
        );
      })}
    </div>
  );
}

export default LegendOptions;
