import React, { useState } from "react";
import "./invoicesGeneratedChart.scss";
import FiltersModal from "../filtersModal";
import { Bar } from "react-chartjs-2";
import { chartDataset, chartOptions } from "../commonChartOptions";
import LegendOptions from "../LegendOptions";

export default function InvoicesGeneratedChart(props) {
  const { selectedUsers, allChartData, isLive } = props;
  let allChartDataFinal = allChartData;
  if (isLive) {
    allChartDataFinal = allChartData.filter((data) => !!data?.totalInvoice);
  }
  let chartDatasetOptions = chartDataset;
  // allChartDataFinal.length < 20 ? { ...chartDataset, barThickness: 30 } : chartDataset ;
  const allFilterOptions = ["Checkouts", "Due invoices", "Split payment", "Number of services ", "Customers", "Sale items"];
  const [selectedFilter, setselectedFilter] = useState("Checkouts");
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const toggleInvoiceFilter = () => {
    setShowFiltersModal(!showFiltersModal);
  };

  const state = {
    labels: allChartDataFinal?.map((data) => (!data?.acronym ? data?.businessName : data?.acronym || "")),
    datasets: selectedFilter?.includes("Number of services")
      ? [
          {
            ...chartDatasetOptions,
            label: "4+ services",
            data: allChartDataFinal?.map((data) => data.fourPlueService),
            backgroundColor: "#E1E8E4",
          },
          {
            ...chartDatasetOptions,
            label: "4 services",
            data: allChartDataFinal?.map((data) => data.fourService),
            backgroundColor: "#84F2D6",
          },
          {
            ...chartDatasetOptions,
            label: "3 services",
            data: allChartDataFinal?.map((data) => data.threeService),
            backgroundColor: "#0B84A5",
          },
          {
            ...chartDatasetOptions,
            label: "2 services",
            data: allChartDataFinal?.map((data) => data.twoService),
            backgroundColor: "#FDB45C",
          },
          {
            ...chartDatasetOptions,
            label: "1 service",
            data: allChartDataFinal?.map((data) => data.oneService),
            backgroundColor: "#46BFBD",
          },
        ]
      : selectedFilter?.includes("Customers")
      ? [
          {
            ...chartDatasetOptions,
            label: "Walk-in customers",
            data: allChartDataFinal?.map((data) => data.walkIn),
            backgroundColor: "#0B84A5",
          },
          {
            ...chartDatasetOptions,
            label: "Returning customers",
            data: allChartDataFinal?.map((data) => data.returningCustomer),
            backgroundColor: "#FDB45C",
          },
          {
            ...chartDatasetOptions,
            label: "New customers",
            data: allChartDataFinal?.map((data) => data.newCustomer),
            backgroundColor: "#46BFBD",
          },
        ]
      : selectedFilter?.includes("Sale items")
      ? [
          {
            ...chartDatasetOptions,
            label: "Mixed",
            data: allChartDataFinal?.map((data) => data.mix),
            backgroundColor: "#0B84A5",
          },
          {
            ...chartDatasetOptions,
            label: "Products",
            data: allChartDataFinal?.map((data) => data.productItem),
            backgroundColor: "#FDB45C",
          },
          {
            ...chartDatasetOptions,
            label: "Services",
            data: allChartDataFinal?.map((data) => data.serviceDetails),
            backgroundColor: "#46BFBD",
          },
        ]
      : [
          {
            ...chartDatasetOptions,
            label: selectedFilter,
            data: allChartDataFinal?.map((data) => {
              let filteredData = [];
              if (selectedFilter === "Checkout") {
                filteredData = data?.completed || 0;
              } else if (selectedFilter === "Due invoices") {
                filteredData = data?.dueInvoice || 0;
              } else if (selectedFilter === "Split payment") {
                filteredData = data?.splitPayment || 0;
              } else {
                filteredData = data?.completed || 0;
              }
              return filteredData;
            }),
            backgroundColor: "#FDB45C",
          },
          {
            ...chartDatasetOptions,
            label: "All invoices",
            data: allChartDataFinal?.map((data) => data?.totalInvoice),
            backgroundColor: "#46BFBD",
          },
        ],
  };

  const legendOptions = state?.datasets
    ?.map((data) => {
      return {
        name: data?.label,
        backgroundColor: data?.backgroundColor,
      };
    })
    .reverse();

  let options = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      tooltip: {
        ...chartOptions.plugins.tooltip,
        callbacks: {
          title: function (context) {
            let title = context[0]?.label;
            if (context[0].dataIndex) {
              title = allChartDataFinal[context[0].dataIndex]?.businessName;
            }
            return title;
          },
        },
      },
    },
  };

  return (
    <>
      <div>
        <div className="invoice-generated-chart-box-content-box">
          <div className="box-header">
            <div>
              <span>
                {allChartDataFinal?.reduce((a, b) => a + +b.totalInvoice, 0)} Invoices generated ({allChartDataFinal?.length})
              </span>
            </div>
            <LegendOptions legendOptions={legendOptions} />
            <div className="filter-button-container">
              <button onClick={toggleInvoiceFilter}>Filters</button>
            </div>
          </div>
          <div className="box-body-content-alignment">
            <div className="grid">
              <div className="grid-items"></div>
              <div className="grid-items">
                <div className="chart-items">
                  <div
                    className={`invoice-chart-container ${
                      selectedFilter?.includes("Customers")
                        ? "adjust-legend-customer"
                        : selectedFilter?.includes("Sale items") && "adjust-legend-sale-item"
                    } `}
                  >
                    <Bar data={state} options={options} />
                  </div>
                </div>
              </div>
            </div>
            <div className="empty-sapce"></div>
          </div>
        </div>
      </div>

      {showFiltersModal && (
        <FiltersModal
          toggle={toggleInvoiceFilter}
          title="Invoices"
          selectedFilter={selectedFilter}
          setselectedFilter={setselectedFilter}
          filterOptions={allFilterOptions}
        />
      )}
    </>
  );
}
