import React, { useEffect, useState, useRef } from "react";
import "./selectInput.scss";
import Popper from "popper.js";
import DownIcon from "../../assets/icons/gray-down.svg";
import PropTypes from "prop-types";

export default function SelectInput(props) {
  const { name, placeholder, DropDown, value, setValue,isPlan, from, handleSelectAccountPlan } = props;

  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const wrapperRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSelectInput = (data) => {
    setValue(data);
    setDropdownOpen(!dropdownOpen);
    if (from === "featuresModal") {
      handleSelectAccountPlan();
    }
  };

  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownOpen(true);
  };
  const closeDropdownPopover = () => {
    setDropdownOpen(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);
  SelectInput.propTypes = {
    children: PropTypes.element.isRequired,
  };

  return (
    <>
      <div className="input relative-custom-select" ref={wrapperRef}>
        <label>{name}</label>
        <div
          className="select-relative-div"
          ref={btnDropdownRef}
          onClick={() => {
            dropdownOpen ? closeDropdownPopover() : openDropdownPopover();
          }}
        >
          <input type="text" value={value} placeholder={placeholder} />
          <div className="icon-alignment">
            <img src={DownIcon} alt="DownIcon" />
          </div>
        </div>

        <div
          ref={popoverDropdownRef}
          className={
            dropdownOpen
              ? "custom-select-react-dropdown custom-select-react-dropdown-open"
              : "custom-select-react-dropdown custom-select-react-dropdown-hidden"
          }
        >
          <div className="all-menu-alignment">
           {isPlan ?
           DropDown?.map((drop) => {
            if(value != drop.planName ){
            return (
              <div className="menu-list" onClick={(e) => handleSelectInput(drop)}>
                <span>{drop.planName}</span>
              </div>
            );
            }
          })
           :
            DropDown?.map((drop) => {
              if(value != drop.Name ){
              return (
                <div className="menu-list" onClick={(e) => handleSelectInput(drop)}>
                  <span>{drop.Name}</span>
                </div>
              );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}
