export const BasicPlanAPI = {
  planName: "Basic",
  planDiscount: "discountedBasicPlanPrice",
  planRate : "basicPlanPrice",
  planDescription:
    "Plan that suits all the businesses. Have all the available features",
  planService: [
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_calendar.svg",
      serviceName: "Appointments",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_file-text.svg",
      serviceName: "Invoices",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_box.svg",
      serviceName: "Inventory",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_user.svg",
      serviceName: "Customer profile",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Frame+58.svg",
      serviceName: "Customer wallet",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_users.svg",
      serviceName: "Staff profile",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/mail.svg",
      serviceName: "SMS, Notifications",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Icon+(1).svg",
      serviceName: "Statement, Daily reports",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/user-check.svg",
      serviceName: "Staff attendance",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_user+(1).svg",
      serviceName: "Product consumption",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/coin-stack+(1).svg",
      serviceName: "Expenses",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Frame+17.svg",
      serviceName: "SMS promotion",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Barbera+logo.svg",
      serviceName: "Online booking app",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/bell.svg",
      serviceName: "Service reminders",
    },
  ],
};
export const ProPlanAPI = {
  planName: "Pro",
  planDiscount: "proPlanDiscountedPrice",
  planRate : "proPlanPrice",
  planDescription:
    "Advanced features to track and improve growth of your salon",
  planService: [
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_calendar.svg",
      serviceName: "Appointments",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_file-text.svg",
      serviceName: "Invoices",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_box.svg",
      serviceName: "Inventory",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_user.svg",
      serviceName: "Customer profile",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Frame+58.svg",
      serviceName: "Customer wallet",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_users.svg",
      serviceName: "Staff profile",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/mail.svg",
      serviceName: "SMS, Notifications",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Icon+(1).svg",
      serviceName: "Statement, Daily reports",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/user-check.svg",
      serviceName: "Staff attendance",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_user+(1).svg",
      serviceName: "Product consumption",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/coin-stack+(1).svg",
      serviceName: "Expenses",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Frame+17.svg",
      serviceName: "SMS promotion",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Barbera+logo.svg",
      serviceName: "Online booking app",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/bell.svg",
      serviceName: "Service reminders",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Icon+(3).svg",
      serviceName: "Membership",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/coin-stack+(1).svg",
      serviceName: "Staff commission",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/%E2%82%B9.svg",
      serviceName: "Staff pay",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/book.svg",
      serviceName: "Ledger/cash register",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/percent.svg",
      serviceName: "Offers",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Vouchers.svg",
      serviceName: "Vouchers",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/briefcase.svg",
      serviceName: "Package",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Vendors.svg",
      serviceName: "Vendors management",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Cart.svg",
      serviceName: "Purchase orders",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/phone-call.svg",
      serviceName: "Enquiry",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/lock.svg",
      serviceName: "Roles & Permissions",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/pie-chart.svg",
      serviceName: "Business analytics",
    },
  ],
};
export const EnterprisePlanAPI = {
  planName: "Enterprise",
  planDiscount: "enterpriesPlanDiscountedPrice",
  planRate : "enterprisePlanPrice",
  planDescription: "Ideal for business with many branches/ outlets",
  planService: [
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_calendar.svg",
      serviceName: "Appointments",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_file-text.svg",
      serviceName: "Invoices",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_box.svg",
      serviceName: "Inventory",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_user.svg",
      serviceName: "Customer profile",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Frame+58.svg",
      serviceName: "Customer wallet",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_users.svg",
      serviceName: "Staff profile",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/mail.svg",
      serviceName: "SMS, Notifications",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Icon+(1).svg",
      serviceName: "Statement, Daily reports",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/user-check.svg",
      serviceName: "Staff attendance",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/feather_user+(1).svg",
      serviceName: "Product consumption",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/coin-stack+(1).svg",
      serviceName: "Expenses",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Frame+17.svg",
      serviceName: "SMS promotion",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Barbera+logo.svg",
      serviceName: "Online booking app",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/bell.svg",
      serviceName: "Service reminders",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Icon+(3).svg",
      serviceName: "Membership",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/coin-stack+(1).svg",
      serviceName: "Staff commission",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/%E2%82%B9.svg",
      serviceName: "Staff pay",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/book.svg",
      serviceName: "Ledger/cash register",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/percent.svg",
      serviceName: "Offers",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Vouchers.svg",
      serviceName: "Vouchers",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/briefcase.svg",
      serviceName: "Package",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Vendors.svg",
      serviceName: "Vendors management",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/Cart.svg",
      serviceName: "Purchase orders",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/phone-call.svg",
      serviceName: "Enquiry",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/lock.svg",
      serviceName: "Roles & Permissions",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/pie-chart.svg",
      serviceName: "Business analytics",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/DashboardIcon.svg",
      serviceName: "Multiple outlet management",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/home.svg",
      serviceName: "Outlet admin",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/briefcase.svg",
      serviceName: "Customized packages",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/bar-chart-2.svg",
      serviceName: "Staff performance dashboard",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/fingerscan.svg",
      serviceName: "Biometric staff attendance",
    },
    {
      icon: "https://expense-icon.s3.ap-south-1.amazonaws.com/gift.svg",
      serviceName: "Reward programs",
    },
  ],
};
export const toastMessage = { 
  allSuccess : {
    modal: true,
    status: false,
    toastmsg: "Plan added sucessfully",
  },
  updateMessage : {
    modal : true,
    status : false, 
    toastmsg : "Plan update sucessfully "
  },
  alreadyExists : {
    modal: true,
    status: true,
    toastmsg: "Country already Exists",
  },
  defaultError : {
    modal: true,
    status: true,
    toastmsg: "An error occurred while adding plans.",
  },
  deleteMessage : {
    modal : true,
    status : false ,
    toastmsg : "Subscription plan deleted successfully"
  }
} 