import React from "react";
import "./couponCodeDetails.scss";
import CloseIcon from "../../../assets/icons/mini-close.svg";
import moment from "moment";

export default function CouponCodeDetails(props) {
  const { couponCodeDetailsModal, setCouponCodeDetailsModal, selectedList } = props;
  console.log("selectedList", selectedList);

  return (
    <>
      <div className="coupon-code-details-wrapper">
        <div className="coupon-code-details-modal">
          <div className="coupon-code-details-modal-header">
            <div onClick={() => setCouponCodeDetailsModal(!couponCodeDetailsModal)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Coupon code redeemed</h2>
          </div>
          <div className="coupon-code-details-modal-body">
            <table>
              <tr>
                <th align="left">Date</th>
                <th align="left">Salon name</th>
              </tr>
              {selectedList?.codeAccess?.map((salon) => {
                return (
                  <tr>
                    <td align="left">{moment(salon?.redeemDate).format("DD MMM ‘YY")}</td>
                    <td align="left">{salon?.businessName}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
