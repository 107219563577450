export const chartOptions = {
  offsetGridLines: false,
  drawTicks: false,
  layout: {
    padding: {
      top: 40,
      left: 20,
      right: 40,
      bottom: 10,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    // tooltip: {
    //   backgroundColor: "#193566",
    //   titleColor: "#FFF",
    // },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      offset: true,
      ticks: {
        padding: 1,
        major: {
          enabled: false,
        },
      },
    },
    y: {
      display: false,
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        padding: 1,
        major: {
          enabled: false,
        },
      },
    },
  },
};

export const chartDataset = {
  borderWidth: 0,
  categoryPercentage: 0.5,
  barPercentage: 1,
  borderRadius: 5,
};
