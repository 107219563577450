import React, { useEffect, useState } from "react";
import BarberaLogo from "../../assets/logo/barbera-logo.svg";
import MenuIcon from "../../assets/icons/menu.svg";

import "./header.scss";
import HeaderMenuModal from "../headerMenuModal";
import AddUserModal from "../modals/AddUserModal";
import RevenueModal from "../modals/revenueModal";
import CouponCode from "../modals/couponCode";
import GenerateCouponCode from "../modals/generateCouponCode";
import CouponCodeDetails from "../modals/couponCodeDetails";

import Auth from "../../helpers/Auth";
import Success from "../Toaster/Success/Success";
import { ApiGet } from "../../helpers/API/ApiData";
import PaymentListModal from "../modals/paymentListModal";
import AnalyticsModal from "../modals/analyticsModal";

export default function Header() {
  const userInfo = Auth.getUserDetail();
  const [sidebarMenu, setSidebarMenu] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const [revenueModalOpen, setRevenueModalOpen] = useState(false);
  const [couponCodeModalOpen, setCouponCodeModalOpen] = useState(false);
  const [generateCouponCodeModal, setGenerateCouponCodeModal] = useState(false);
  const [analyticsModalOpen, setAnalyticsModalOpen] = useState(false);
  const [couponCodeDetailsModal, setCouponCodeDetailsModal] = useState(false);
  const [accountManagersModal, setAccountManagersModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastmsg, setToastmsg] = useState();
  const [er, setEr] = useState();
  const [couponDetails, setCouponDetails] = useState([]);
  const [selectedList, setSelectedList] = useState();
  const [paymentData, setPaymentData] = useState(false);
  const AddNewuserModal = (data) => {
    setAddUserModal(!addUserModal);
    if (data === true) {
      setSuccess(true);
      setToastmsg("User added sucessfully!");
    }
  };

  const toggleAnalyticsModal = () => {
    setAnalyticsModalOpen(!analyticsModalOpen);
  };

  const mineData = async () => {
    try {
      const allCouponRes = await ApiGet("coupen");
      if (allCouponRes?.data?.status === 200) {
        setCouponDetails(allCouponRes?.data?.data?.length === 0 ? [] : allCouponRes?.data?.data?.reverse());
      } else {
        console.log(allCouponRes?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setEr();
    }, 1500);
    return () => clearTimeout(timer);
  });
  const handlePaymentData = () => {
    setPaymentData(!paymentData);
  };

  return (
    <>
      <header>
        <div className="header-alignment">
          <div className="logo">
            <img src={BarberaLogo} alt="BarberaLogo" />
          </div>
          <div className="menu">
            <div className="add-new-user" onClick={() => AddNewuserModal()}>
              <button>Add new user</button>
            </div>
            {userInfo?.role === "SuperAdmin" && (
              <div className="menu-bar" onClick={() => setSidebarMenu(!sidebarMenu)}>
                <img src={MenuIcon} alt="MenuIcon" />
              </div>
            )}
          </div>
        </div>
      </header>
      {userInfo?.role === "SuperAdmin" && (
        <HeaderMenuModal
          setSidebarMenu={setSidebarMenu}
          setAddUserModalOpen={setAddUserModalOpen}
          setRevenueModalOpen={setRevenueModalOpen}
          analyticsModalOpen={analyticsModalOpen}
          setAnalyticsModalOpen={setAnalyticsModalOpen}
          setCouponCodeModalOpen={setCouponCodeModalOpen}
          setAccountManagersModal={setAccountManagersModal}
          sidebarMenu={sidebarMenu}
          addUserModalOpen={addUserModalOpen}
          revenueModalOpen={revenueModalOpen}
          couponCodeModalOpen={couponCodeModalOpen}
          accountManagersModal={accountManagersModal}
          handlePaymentData={handlePaymentData}
        />
      )}
      {addUserModal && <AddUserModal toggle={AddNewuserModal} addUserModal={addUserModal} />}
      {revenueModalOpen && <RevenueModal setRevenueModalOpen={setRevenueModalOpen} revenueModalOpen={revenueModalOpen} />}
      {couponCodeModalOpen && (
        <CouponCode
          setCouponCodeModalOpen={setCouponCodeModalOpen}
          setCouponCodeDetailsModal={setCouponCodeDetailsModal}
          setGenerateCouponCodeModal={setGenerateCouponCodeModal}
          couponCodeModalOpen={couponCodeModalOpen}
          couponCodeDetailsModal={couponCodeDetailsModal}
          generateCouponCodeModal={generateCouponCodeModal}
          setSelectedList={setSelectedList}
          mineData={mineData}
          couponDetails={couponDetails}
        />
      )}
      {generateCouponCodeModal && (
        <GenerateCouponCode
          setGenerateCouponCodeModal={setGenerateCouponCodeModal}
          generateCouponCodeModal={generateCouponCodeModal}
          mineData={mineData}
          couponDetails={couponDetails}
          setSuccess={setSuccess}
          setEr={setEr}
          setToastmsg={setToastmsg}
        />
      )}
      {couponCodeDetailsModal && (
        <CouponCodeDetails
          setCouponCodeDetailsModal={setCouponCodeDetailsModal}
          couponCodeDetailsModal={couponCodeDetailsModal}
          selectedList={selectedList}
        />
      )}
      {paymentData && <PaymentListModal handlePaymentData={handlePaymentData} />}
      {analyticsModalOpen && <AnalyticsModal toggle={toggleAnalyticsModal} />}
      {success && <Success modal={success} toastmsg={toastmsg} er={er} />}
    </>
  );
}
