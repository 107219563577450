import React, { useState, useEffect } from "react";
import "./accountUpgrade.scss";
import CloseIcon from "../../../assets/icons/mini-close.svg";
import SelectInput from "../../selectInput/index";
import Auth from "../../../helpers/Auth";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";

export default function AccountUpgrade(props) {
  const { toggle, userDetails } = props;
  let userInfo = Auth.getUserDetail();
  const [disable, setDisable] = useState(true);
  const [managerDetails, setManagerDetails] = useState([]);
  const [manager, setManager] = useState(userInfo);
  const [planFeture, setPlanFeture] = useState([]);
  const [validity, setValidity] = useState();
  const [FinalPrice, setFinalPrice] = useState();
  const [invoiceNo, setInvoiceNo] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [featuresListJson, setFeaturesListJson] = useState([
    { name: "Appointments", slug: "appointment", isChecked: true, type: "basic" },
    { name: "Invoices", slug: "invoice", isChecked: true, type: "basic" },
    { name: "Inventory", slug: "inventory", isChecked: true, type: "basic" },
    { name: "Customer profile", slug: "customerProfile", isChecked: true, type: "basic" },
    { name: "Customer wallet", slug: "customerWallet", isChecked: true, type: "basic" },
    { name: "Staff profile", slug: "staffProfile", isChecked: true, type: "basic" },
    { name: "Staff attendance", slug: "staff", isChecked: true, type: "basic" },
    { name: "SMS, Notifications", slug: "sms", isChecked: true, type: "basic" },
    { name: "Statement, Daily reports", slug: "statement", isChecked: true, type: "basic" },
    { name: "Product consumption", slug: "product", isChecked: true, type: "basic" },
    { name: "Expenses", slug: "expenses", isChecked: true, type: "basic" },
    { name: "SMS promotion", slug: "smsPromotion", isChecked: true, type: "basic" },
    { name: "Online booking app", slug: "onlineBookingApp", isChecked: true, type: "basic" },
    { name: "Service reminders", slug: "serviceReminder", isChecked: true, type: "basic" },

    { name: "Membership", slug: "membership", isChecked: true, type: "professional" },
    { name: "Staff commission", slug: "staffCommission", isChecked: true, type: "professional" },
    { name: "Staff pay", slug: "staffPayroll", isChecked: true, type: "professional" },
    { name: "Ledger/cash register", slug: "leager", isChecked: true, type: "professional" },
    { name: "Offers", slug: "offers", isChecked: true, type: "professional" },
    { name: "Vouchers", slug: "vouchers", isChecked: true, type: "professional" },
    { name: "Package", slug: "package", isChecked: true, type: "professional" },
    { name: "Vendors management", slug: "vendorsManagement", isChecked: true, type: "professional" },
    { name: "Purchase orders", slug: "purchaseOrders", isChecked: true, type: "professional" },
    { name: "Enquiry", slug: "enquiry", isChecked: true, type: "professional" },
    { name: "Roles & Permissions", slug: "rolesPermission", isChecked: true, type: "professional" },
    { name: "Business analytics", slug: "businessAnalytics", isChecked: true, type: "professional" },

    { name: "Multiple outlet management", slug: "outlets", isChecked: true, type: "enterprise" },
    { name: "Outlet admin", slug: "outletsAdmin", isChecked: true, type: "enterprise" },
    { name: "Customized packages", slug: "customizedPackages", isChecked: true, type: "enterprise" },
    { name: "Staff performance dashboard", slug: "staffPerformanceDashboard", isChecked: true, type: "enterprise" },
    { name: "Biometric staff attendance", slug: "biometricStaffAttendance", isChecked: true, type: "enterprise" },
    { name: "Reward programs", slug: "rewardPrograms", isChecked: true, type: "enterprise" },
  ]);
  const [featureList, setFeatureList] = useState([]);
  console.log("featureList",featureList)
  useEffect(async () => {
    let resp = await ApiGet("monetizePlan");
    if (resp.data.status === 200) {
      setPlanFeture(resp.data.data);
      setSelectedPlan(resp.data.data[0])
    }
  }, []);

  useEffect(() => {
    getAccountFeaturesList()
    gettingManagerDetails();
  }, []);

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };


  const gettingManagerDetails = async () => {
    await ApiGet("account/manager/getAllmanager")
      .then((res) => {
        // Admin added for select manager Dropdown
        let managers = res?.data?.data?.map((user) => {
          return user?.data;
        });
        let finalData = [userInfo].concat(managers);
        setManagerDetails(finalData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAccountFeaturesList = async () => {
    let res = await ApiGet(`features/company/${userDetails?.accountData[0]?.companyId}`);
    if (res.data.status === 200) {
      let tempFeaturesData = res.data.data[0] || {};
      setFeatureList(tempFeaturesData);

    } else {
      console.log("err ftr", res.data.message);
    }
  };
  const updateFeatureData = async (selectedPlanName) => {
    let thisPlanServicesList = featuresListJson?.reduce((a, b) => {
      return selectedPlanName === "Basic"
        ? b?.type === "basic"
          ? { ...a, [b?.slug]: true }
          : { ...a, [b?.slug]: false }
          : selectedPlanName === "Pro"
          ? b?.type === "professional" || b?.type === "basic"
            ? { ...a, [b?.slug]: true }
            : { ...a, [b?.slug]: false }
        : { ...a, [b?.slug]: true };
    }, {});

    let payload = { ...thisPlanServicesList, companyId: userDetails?.accountData[0]?.companyId };

    try {
      let updateFeaturesRes = await (featureList?._id
        ? ApiPut(`features/${featureList?._id}`, payload)
        : ApiPost("features", payload));
      if (updateFeaturesRes?.data?.status === 200) {
        console.log("Features updated successfully");

      } else {
        console.log(updateFeaturesRes.data.message);
      }
    } catch {
      console.log("Error updating features");
    }
  };
  console.log("userDetails",featureList)
  const UpgradeAccount = async() => {
    let paymentID
      let bodyy = {
        paymentGatewayId:"Cash",
        amount:FinalPrice,
        companyId:userDetails?.accountData[0]?.companyId,
        type:"Payment"
      }
      await ApiPost("campaignPayment", bodyy)
      .then(async(res)=> {
        paymentID=res?.data?.data?._id
      })
      .catch((err)=> {
        console.log("err", err);
      })
    
    let PlanDetail;
    let planpayment = [
      {
        planId: selectedPlan,
        planBasePrice: parseInt(FinalPrice / (1 + 18 / 100)),
        planGstAmount: FinalPrice - parseInt(FinalPrice / (1 + 18 / 100)),
        paymentAmount: FinalPrice,
        paymentStartDate: moment.utc(new Date()).format(),
        paymentEndDate: moment.utc(new Date()).add(validity, "days").format(),
        isActive: true,
        companyId: userDetails?.accountData[0]?.companyId,
        razorpaymentId: paymentID ? paymentID :"Cash",
        paymentId:invoiceNo
      },
    ];

    if (userDetails?.monetize[0]?.paymentData?.length > 0) {
      planpayment = userDetails?.monetize[0]?.paymentData.concat(planpayment);
    }
    if (userDetails?.monetize?.length > 0 && userDetails?.noOfDays > 0) {
      PlanDetail = {
        finalDate: moment.utc(userDetails?.monetize[0]?.finalDate).add(validity, "days").format(),
        paymentData: planpayment,
        companyId: userDetails?.accountData[0]?.companyId,
      };
    } else {
      PlanDetail = {
        finalDate: moment.utc(new Date()).add(validity, "days").format(),
        paymentData: planpayment,
        companyId: userDetails?.accountData[0]?.companyId,
      };
    }
    await ApiPost("monetize", PlanDetail)
    .then((res) => {
      addRevenueDetails()
      updateFeatureData(selectedPlan?.planName);
    })
    .catch((err) => {
      console.log("err", err);
    });
  };

  
  const addRevenueDetails=async(userData)=>{
      let revenueDetail =  {
        date:new Date(),
        salonName: userDetails?.accountData[0],
        accountManagerId:manager._id,
        validity:validity,
        amount:FinalPrice
        }
        await ApiPost("managerRevenue", revenueDetail)
        .then(async (res) => {
          toggle(true)
        })
        .catch((err) => {
          console.log(err);
        })
  }
  console.log("planFeture",planFeture)
  return (
    <>
      <div className="account-upgrade-modal-wrapper">
        <div className="account-upgrade-small-modal">
          <div className="account-upgrade-small-modal-header">
            <div onClick={() => toggle()}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Account upgrade</h2>
          </div>
          <div className="account-upgrade-small-modal-body">
            <div className="box-title-alignment">
              <p>Current validity - {userDetails?.noOfDays} days </p>
            </div>
            <SelectInput name={"Select Plan name"} value={selectedPlan?.planName} setValue={setSelectedPlan} DropDown={planFeture}  isPlan={true}/>
            <div className="input">
              <label>Invoice no.</label>
              <input type="number" name="planName" value={invoiceNo}  onChange={(e)=>setInvoiceNo(e.target.value)}/>
            </div>
            <div className="input">
              <label>Account validity</label>
              <div className="account-relative-div">
                <input type="number" placeholder="Enter value" value={validity}   onChange={(e) => setValidity(e.target.value)} />
                <div className="days-text-alignment">
                  <span>days</span>
                </div>
              </div>
            </div>
            <div className="input">
              <label>Amount paid</label>
              <div className="account-relative-div">
                <input type="number" placeholder="Enter value" value={FinalPrice}   onChange={(e) => setFinalPrice(e.target.value)} />
                <div className="days-text-alignment">
                  <span><a>₹</a></span>
                </div>
              </div>
            </div>
            {userInfo?.role === "SuperAdmin" ?  <SelectInput name={"Account manager"} value={manager?.Name} setValue={setManager} DropDown={managerDetails} />:       <div className="input">
                  <label>
                    Account manager 
                  </label>{" "}
                  <input
                    type="text"
                    name="mobileNumber"
                    disabled
                    value={manager?.Name}
                  />
                </div>}
          </div>
          <div className="account-upgrade-small-modal-footer">
            <div className="blue-button">
              {manager && validity && validity != 0 && FinalPrice && FinalPrice != 0 && invoiceNo && selectedPlan ? (
                <button onClick={() => UpgradeAccount()}> Mark as paid</button>
              ) : (
                <button style={{ opacity: 0.5 }}> Mark as paid</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
