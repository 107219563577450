import React, { useState } from "react";
import "./attendance.scss";
import { Bar } from "react-chartjs-2";
import { chartDataset, chartOptions, LegnedOption } from "../commonChartOptions";
import LegendOptions from "../LegendOptions";

export default function AttendanceChart(props) {
  const { selectedUsers, allChartData, dateDifference, isLive } = props;

  let allChartDataFinal = allChartData;
  if (isLive) {
    allChartDataFinal = allChartData.filter((data) => !!data?.attendenceLists);
  }
  let chartDatasetOptions = chartDataset;
  // allChartDataFinal.length < 20 ? { ...chartDataset, barThickness: 30 } : chartDataset;

  const state = {
    labels: allChartDataFinal?.map((data) => (!data?.acronym ? data?.businessName : data?.acronym || "")),
    datasets: [
      // {
      // ...chartDatasetOptions,
      //   label: "Staff requests",
      //   backgroundColor: "#FDB45C",
      //   data: allChartDataFinal?.map((data) => data?.attendenceLists),
      // },
      {
        ...chartDatasetOptions,
        // label: "Operator driven",
        label: "Present",
        backgroundColor: "#46BFBD",
        data: allChartDataFinal?.map((data) => data?.attendenceLists / dateDifference),
      },
    ],
  };

  const legendOptions = state?.datasets
    ?.map((data) => {
      return {
        name: data?.label,
        backgroundColor: data?.backgroundColor,
      };
    })
    .reverse();

  let options = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      tooltip: {
        ...chartOptions.plugins.tooltip,
        callbacks: {
          title: function (context) {
            let title = context[0]?.label;
            if (context[0].dataIndex) {
              title = allChartDataFinal[context[0].dataIndex]?.businessName;
            }
            return title;
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="invoice-generated-chart-box-content-box">
        <div className="box-header">
          <div className="title">
            <span>Attendance ({allChartDataFinal?.length})</span>
          </div>
          {/* <LegendOptions legendOptions={legendOptions} /> */}
        </div>
        <div className="box-body-content-alignment">
          <div className="grid">
            <div className="grid-items"></div>
            <div className="grid-items">
              <div className="chart-items">
                <div className="attendance-chart-container">
                  <Bar data={state} options={options} />
                </div>
              </div>
            </div>
          </div>
          <div className="empty-sapce"></div>
        </div>
      </div>
    </div>
  );
}
