import React, { useState } from "react";
import "./Toaster.scss";


export default function AcceptDeny(props) {


  return (
    <>
      <div className="toaster-modal">
        <div className={props.modal ? "modal-design-toster toster-show" : " modal-design-toster toster-hidden"}>
          <div className="toster-title">
            <p>{`Are you sure that you want to ${props.type ? "Deactive":"Active"} SMS Service?`}</p>
          </div>
          <div className="toster-footer">
            <button onClick={() => props.toggle()}>Cancel</button>
            <button style={{ backgroundColor: "#1479FF" }} onClick={() => props.toggle(true)}>
              <span style={{ paddingLeft: "8px", fontcolor: "#fff" }}>Yes</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
