import React, { useState } from "react";
import "./Toaster.scss";
import UserIcon from "../../assets/icons/user.svg";
import DeleteIcon from "../../assets/icons/white-delete.svg";
import { ApiPut } from "../../helpers/API/ApiData";

export default function DeleteConfirmationModal(props) {
  const { toggle,deleteName,handleDeleteData  } = props;


  return (
    <>
      <div className="toaster-modal">
        <div className={props.modal ? "modal-design-toster toster-show" : " modal-design-toster toster-hidden"}>
          <div className="toster-title">
            <p>Are you sure you want to delete a { deleteName} from database?</p>
          </div>
          <div className="toster-footer">
            <button onClick={() => toggle()}>Cancel</button>
            <button onClick={() => handleDeleteData()} className={"red-button-style"} >
                <img src={DeleteIcon} alt="DeleteIcon" />
                <span style={{ paddingLeft: "8px" }}>Delete</span>
              </button>
          </div>
        </div>
      </div>
    </>
  );
}
