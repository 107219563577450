import React, { useEffect, useState } from "react";
import "./adminDashboard.scss";
import UserIcon from "../../assets/icons/user-button.svg";
import FilterUserModal from "./filterUserModal";
import AllDashboardCharts from "./AllDashboardCharts";
import { ApiGet, ApiPost } from "../../helpers/API/ApiData";
import moment from "moment";
import DatePicker from "react-datepicker";
import CalendarIcon from "../../assets/icons/calendar_dark.svg";
import Loader from "../common/Loader";
import { useDispatch } from "react-redux";
import { setUsersStore } from "../../store/slices/usersSlice";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const [showSelectUsersModal, setShowSelectUsersModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allChartData, setAllChartData] = useState([]);
  const [startDate, setStartDate] = useState(moment()._d);
  const [endDate, setEndDate] = useState(moment()._d);
  const [updateCharts, setUpdateCharts] = useState(false);
  const [dateDifference, setDateDifference] = useState(1);
  const [isLive, setIsLive] = useState(false);
  const [activeFilterBtn, setActiveFilterBtn] = useState("Live");
  const [isLiveLoading, setIsLiveLoading] = useState(false);

  const selectUsersModalToggle = () => {
    setShowSelectUsersModal(!showSelectUsersModal);
  };

  const toggleLive = (key) => {
    setIsLive(true);
    if (key === activeFilterBtn) {
      if (activeFilterBtn !== "Live") {
        setActiveFilterBtn("Live");
        setSelectedUsers(allUsers);
      } else {
        setActiveFilterBtn("Selected Users");
        if (localStorage.getItem("selectedUsers")) {
          setSelectedUsers(JSON.parse(localStorage.getItem("selectedUsers")));
        } else {
          setSelectedUsers(allUsers);
        }
      }
    } else if (
      (activeFilterBtn === "Paid Users" || activeFilterBtn === "Trial Users" || activeFilterBtn === "All Users") &&
      key !== "Live" &&
      key !== "Selected Users"
    ) {
      if (activeFilterBtn === "All Users") {
        setActiveFilterBtn(key === "Paid Users" ? "Trial Users" : key === "Trial Users" && "Paid Users");
      } else {
        setActiveFilterBtn("All Users");
      }
      if (localStorage.getItem("selectedUsers")) {
        setSelectedUsers(JSON.parse(localStorage.getItem("selectedUsers")));
      } else {
        setSelectedUsers([]);
      }
    } else {
      setActiveFilterBtn(key);
      if (localStorage.getItem("selectedUsers")) {
        setSelectedUsers(JSON.parse(localStorage.getItem("selectedUsers")));
      } else {
        setSelectedUsers([]);
      }
    }
    setIsLiveLoading(true);
  };

  const removeDuplicateObjectFromArray = (array, key) => {
    return new Map(array.map((v) => [v.accountData[0]?.[key], v])).values();
  };

  const fetchAllUsers = async () => {
    try {
      await ApiGet("account/manager/getAllAdminandOwner")
        .then((res) => {
          let data = removeDuplicateObjectFromArray(res?.data?.data, "companyId");
          let tempUserData = [...data].reverse();
          setAllUsers(tempUserData);
          dispatch(setUsersStore(tempUserData));

          if (localStorage.getItem("selectedUsers")) {
            setSelectedUsers(JSON.parse(localStorage.getItem("selectedUsers")));
          } else {
            setSelectedUsers([...data]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSelectedUsersData = async (data) => {
    let payload = data;
    if (!data?.endTime || !data) {
      return;
    }
    try {
      let getUsersRes = await ApiPost("dashboard/adminDashboard/adminstatistic", payload);
      if (getUsersRes.data.status === 200) {
        setAllChartData(getUsersRes.data.data);
        setUpdateCharts(!updateCharts);
        if (isLiveLoading) {
          setIsLive(!isLive);
          setIsLiveLoading(!isLiveLoading);
        }
      } else {
        console.log(getUsersRes.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // if (start && end) {
    //   let data = {
    //     startTime: moment(start).format("YYYY-MM-DD"),
    //     endTime: moment(end).add(1, "days").format("YYYY-MM-DD"),
    //     companyId: selectedUsers.map((data) => data?.accountData[0]?.companyId),
    //   };
    //   fetchSelectedUsersData(data);
    //   let a = moment(start, "DD.MM.YYYY");
    //   let b = moment(moment(end).add(1, "days"), "DD.MM.YYYY");
    //   let daysDifference = b.diff(a, "days");
    //   setDateDifference(daysDifference);
    // }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    // console.log("allUsers", allUsers);
    let targetedUsers = [];

    if (!endDate || allUsers.length === 0) {
      return;
    }

    if (activeFilterBtn === "Selected Users") {
      if (selectedUsers.length > 0) {
        targetedUsers = selectedUsers;
      } else if (localStorage.getItem("selectedUsers")) {
        let tempSelectedUsers = JSON.parse(localStorage.getItem("selectedUsers"));
        targetedUsers = tempSelectedUsers.map((data) => data?.accountData[0]?.companyId);
      }
    } else if (activeFilterBtn === "Paid Users") {
      targetedUsers = allUsers?.reduce((a, b, i) => {
        if ((b?.noOfDays > 0 && b?.monetize[0]?.paymentData?.length > 0) || (b?.noOfDays == 0 && b?.monetize[0]?.paymentData?.length > 0)) {
          return [...a, b];
        } else {
          return a;
        }
      }, []);
    } else if (activeFilterBtn === "Trial Users") {
      targetedUsers = allUsers?.reduce((a, b, i) => {
        if (b?.noOfDays > 0 && b?.monetize[0]?.paymentData?.length == 0) {
          return [...a, b];
        } else {
          return a;
        }
      }, []);
    } else if (activeFilterBtn === "All Users") {
      targetedUsers = allUsers?.reduce((a, b, i) => {
        if (
          (b?.noOfDays > 0 && b?.monetize[0]?.paymentData?.length > 0) ||
          (b?.noOfDays == 0 && b?.monetize[0]?.paymentData?.length > 0) ||
          (b?.noOfDays > 0 && b?.monetize[0]?.paymentData?.length == 0)
        ) {
          return [...a, b];
        } else {
          return a;
        }
      }, []);
    } else if (activeFilterBtn === "Live") {
      targetedUsers = allUsers;
    }

    let data = {
      startTime: moment(startDate).format("YYYY-MM-DD"),
      endTime: moment(endDate).add(1, "days").format("YYYY-MM-DD"),
    };

    if (targetedUsers.length > 0) {
      data = {
        ...data,
        companyId: targetedUsers.map((data) => data?.accountData?.[0]?.companyId),
      };
    } else if (localStorage.getItem("selectedUsers")) {
      let tempSelectedUsers = JSON.parse(localStorage.getItem("selectedUsers"));
      data = {
        ...data,
        companyId: tempSelectedUsers.map((data) => data?.accountData[0]?.companyId),
      };
    } else {
      return;
    }
    fetchSelectedUsersData(data);
  }, [selectedUsers, activeFilterBtn, endDate]);

  return (
    <>
      {isLiveLoading && <Loader />}
      <div className="admin-dashboard-all-content-alignment">
        <div className="admin-dashboard-first-header-alignment">
          <div className="user-button-design">
            <button onClick={selectUsersModalToggle} className={`${activeFilterBtn === "Selected Users" && "active"}`}>
              <img src={UserIcon} alt="UserIcon" />
              <span>
                {selectedUsers?.length === 0 ? "No" : selectedUsers?.length === allUsers?.length ? "All" : selectedUsers?.length} users selected
              </span>
            </button>
            <button
              onClick={(e) => toggleLive("Live")}
              className={`filter-btn live-btn  ${activeFilterBtn === "Live" && "active"} ${activeFilterBtn === "Live" && isLiveLoading && "loading"}`}
            >
              {activeFilterBtn === "Live" && <span className="live-dot" />}
              <span>Live</span>
            </button>
            <button
              onClick={(e) => toggleLive("Paid Users")}
              className={`filter-btn users-btn  ${(activeFilterBtn === "Paid Users" || activeFilterBtn === "All Users") && "active"} ${
                (activeFilterBtn === "Paid Users" || activeFilterBtn === "All Users") && isLiveLoading && "loading"
              }`}
            >
              <span>Paid users</span>
            </button>
            <button
              onClick={(e) => toggleLive("Trial Users")}
              className={`filter-btn users-btn ${(activeFilterBtn === "Trial Users" || activeFilterBtn === "All Users") && "active"} ${
                (activeFilterBtn === "Trial Users" || activeFilterBtn === "All Users") && isLiveLoading && "loading"
              }`}
            >
              <span>On-trial users</span>
            </button>
          </div>
          <div className="react-date-picker-style">
            <img src={CalendarIcon} alt="CalendarIcon" />
            <span>
              <DatePicker
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Date"
                dateFormat="d MMM ''yy"
                onChange={handleOnChange}
                onKeyDown={(e) => e.preventDefault()}
                maxDate={new Date()}
                selectsRange
                fixedHeight
              />
            </span>
          </div>
        </div>
        <div className="all-chart-box-all-content-alignment">
          <AllDashboardCharts
            selectedUsers={selectedUsers}
            allChartData={allChartData}
            updateCharts={updateCharts}
            dateDifference={dateDifference}
            isLive={activeFilterBtn === "Live"}
          />
        </div>
      </div>
      {showSelectUsersModal && (
        <FilterUserModal
          toggle={selectUsersModalToggle}
          allUsers={allUsers}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          toggleLive={toggleLive}
          setActiveFilterBtn={setActiveFilterBtn}
        />
      )}
    </>
  );
}
