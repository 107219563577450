import React from "react";
import "./ViewPromoteContentModal.scss";
import CloseIcon from "../../../assets/icons/mini-close.svg";
import DownloadIcon from "../../../assets/icons/download-icon.svg";
import { saveAs } from 'file-saver'

function ViewPromoteContentModal(props) {
  const { toggle ,viewampaignData} = props;
  return (
    <div className="coupon-code-details-wrapper">
      <div className="coupon-code-details-modal">
        <div className="coupon-code-details-modal-header">
          <div onClick={toggle}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
          <h2>Campaign content</h2>
        </div>
        <div className="coupon-code-details-modal-body">
          <div className="message chat-view-design">
            <p dangerouslySetInnerHTML={{__html: viewampaignData?.fullSMSDetails.replace(/\n/g, "<br />").replace(/<small>/g, "").replace(/Name/g, "").replace("(", "").replace("),", "")}}>
            </p>
          </div>
          {viewampaignData?.smsImage && <div className="image">
            <span>{viewampaignData?.smsImageName}</span> <img src={DownloadIcon} alt="download" onClick={()=>   saveAs(viewampaignData?.smsImage, 'image.jpg') }/>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default ViewPromoteContentModal;
