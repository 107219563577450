import React, { useEffect, useState } from "react";
import "../AddUserModal/AddUserModal.scss";
import CloseIcon from "../../../assets/icons/new-close.svg";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import SelectInput from "../../selectInput";
import moment from "moment";
import * as userUtil from "../../../utils/user.util";
import { log } from "sass-lint/lib/helpers";
import Success from "../../Toaster/Success/Success";
import EyeIcon from "../../../assets/icons/eye-off.svg";
import EyeOnIcon from "../../../assets/icons/eye-gray.svg";

export default function AddNewManager(props) {
  // const dispatch = useDispatch();
  const { toggle, editData } = props;
  const [success, setSuccess] = useState(false);
  const [toastmsg, setToastmsg] = useState();
  const [er, setEr] = useState();
  const [fullName, setFullName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disable, setDisable] = useState(true);
  const [errors, setError] = useState({});
  const toTitleCase = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
  useEffect(() => {
    if (editData) {
      setFullName(editData?.Name);
      setPhoneNumber(editData?.mobileNumber);
      setEmail(editData?.email);
      setPassword(editData?.password);
      setDisable(true);
    }
  }, [editData]);
  const colorList = ["#FFD5C2", "#FFD3AA", "#FEFFC2", "#CBF9FF", "#D1FFF4", "#C2FFC8", "#F5CBFF", "#FFD8FD", "#FBDEE3", "#FFD6E3"];

 

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  const validate=()=>{
    let errors = {};
    let formIsValid = true;

    if (!fullName) {
        formIsValid = false;
        errors["fullname"] = "* Enter valid input";
      }
    if (fullName?.trim() == "") {
      formIsValid = false;
      errors["fullname"] = "* Enter valid input";
    }
 
    if (phoneNumber?.trim() == "") {
        formIsValid = false;
        errors["mobilenumber"] = "* Enter valid Mobile number";
      }
     if (phoneNumber.length < 10) {
        formIsValid = false;
        errors["mobilenumber"] = "* Enter valid Mobile number";
      }
      if (phoneNumber.length > 10) {
        formIsValid = false;
        errors["mobilenumber"] = "* Enter valid Mobile number";
      }
      if (!email) {
        formIsValid = false;
        errors["email"] = "* Enter valid Email";
      }
      if (!EMAIL_REGEX.test(email)) {
        formIsValid = false;
        errors["email"] = "* Enter valid Email";
      }
      if (!password) {
        formIsValid = false;
        errors["password"] = "* Enter Password";
      }
    setError(errors);
    return formIsValid;
  }
  const handleAddManager = async () => {
    if (validate()) {
      let payload = {
        role: "Manager",
        password: password,
        mobileNumber: phoneNumber,
        // businessName: businessName,
        Name: fullName,
        firstName: fullName,
        nameOfSalonOwner: fullName,
        email: email,
      };
      if (editData) {
        try {
          let res = await ApiPut(`account/${editData._id}`, payload);
          if (res.data.status === 200) {
            toggle("edit");
          }
        } catch (err) {
          setSuccess(true);
          setEr("Error");
          setToastmsg("Something went wrong!");
        }
      } else {
        try {
          let res = await ApiPost("account/", payload);
          if (res.data.status === 200) {
            // toggleTabPane("fourth");

            getAccountInfo(res.data.data._id);
            toggle(true);
            // dispatch(setOnboardingTourProgress(0));
            // dispatch(setOnboardingStatus(null));
          }else{
            setSuccess(true);
            setEr("Error");
            setToastmsg("User already exists!");
          }
        } catch (err) {
          setSuccess(true);
          setEr("Error");
          setToastmsg("Something went wrong!");
        }
      }
    }
  };

  const getAccountInfo = async (id) => {
    try {
      let res = await ApiGet("account/" + id);
      if (res.data.status === 200) {
        // settingData(res?.data?.data[0]?.companyId);

        //create default payment method
        for (var i = 0; i < 3; i++) {
          createPaymentMethod(res?.data?.data[0]?.companyId, i === 0 ? "Cash" : i == 1 ? "Debit/Credit Card" : "UPI", i == 0 ? true : false);
        }
        //add data for defult whatsup sms
        updateDailyWhatsup(res?.data?.data[0]);

        //add default trial days
        trialDays(res?.data?.data[0]?.companyId);

        //create default setting
        setOnobardingData(res?.data?.data[0]?.companyId);

        //create default staff
        createStaff(res?.data?.data[0]?.companyId);

        //create default category
        createCategory(res?.data?.data[0]?.companyId);

        //create default color panel
        colorList.forEach((element) => {
          createColor(res?.data?.data[0]?.companyId, element);
        });

        // dispatch(setUserInfoRed(res.data.data[0]));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const trialDays = async (companyId) => {
    let PlanDetail = {
      finalDate: moment.utc(new Date()).add(15, "days").format(),
      paymentData: [],
      companyId: companyId,
    };
    let resp = await ApiPost("monetize", PlanDetail);
  };
  const defaultData = [
    [
      "Sales",
      [
        "Total sales",
        "Number of generated invoices",
        "Number of products sold",
        "Average ticket size",
        "Discount offered & Tax",
        "Due amount & Due invoices",
      ],
    ],
    ["Appointments", ["Total appointments", "Total services availed", "Popular hours"]],
    [
      "Collections",
      ["Total sales collections", "Opening collection", "Closing collection", "Expense & Staff pay", "Receive from Owner", "Transfer to Owner"],
    ],
    ["Staff", ["Staff wise sales", "Staff performance"]],
    [
      "Customers",
      [
        "Available wallet balance",
        "Wallet top-ups",
        "Wallet redeemed",
        "Wallet Withdrawals",
        "Previous due paid",
        "Customer reviews",
        "Custmomer visits",
        "Number of customers added to the system",
      ],
    ],
  ];

  const updateDailyWhatsup = async (user) => {
    let updated = {
      permission: defaultData,
      user: [{ contactName: user?.Name, contactNumber: user?.mobileNumber }],
      time: moment("23:00", "hh-mm a")._d,
      companyId: user?.companyId,
      featureOn: true,
    };
    await ApiPost("statement", updated)
      .then(async (res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const setOnobardingData = async (companyId) => {
    let onboardingInitData = {
      firstDay: "Sunday",
      bookingInterval: "15",
      storeTiming: [
        {
          isStoreClosed: false,
          day: "Sunday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Monday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Tuesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Wednesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Thursday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Friday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          day: "Saturday",
          starttime: "10:00",
          endtime: "20:00",
        },
      ],
      multipleStaff: { assignMultipleStaff: true },
      attendence: { attendanceToggle: true },
      workingDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      customer: false,
      iCustomer: false,
      appointments: true,
      service: true,
      staff: true,
      serviceTime: "15",
      companyId: companyId,
      inventory: {
        enableInventory: true,
        productType: "Store Consumable & Retail",
        enableBarcode: false,
      },
      tax: {
        gstCharge: false,
        gstNumber: "",
        serviceTax: false,
        serviceTaxPer: "CGST 9%, SGST 9%",
        productTax: false,
        productTaxPer: [],
      },
      paymentMethod: ["Cash", "Debit/Credit Card", "UPI"],
      onboardProcess: [
        {
          onboardingStatus: null,
          onboardingCompleted: false,
          onboardingTourProgress: 0,
          onboardingProfileUpdated: false,
        },
      ],
      membership: {
        membership: true,
        membershipBenefits: "Free & Discounted services",
        applyMembershipBenefits: true,
      },
      firstReminderDate: new Date(),
      secondReminderDate: new Date(),
      thirdReminderDate: new Date(),
      currentType: "₹",
    };
    await ApiPost("setting/", onboardingInitData)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const createStaff = async (companyId) => {
    let body = {
      firstName: "Unassign",
      lastName: "",
      mobileNumber: "9898975574",
      workingDays: [
        {
          isStoreClosed: false,
          Day: "Sunday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Monday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Tuesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Wednesday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Thursday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Friday",
          starttime: "10:00",
          endtime: "20:00",
        },
        {
          isStoreClosed: false,
          Day: "Saturday",
          starttime: "10:00",
          endtime: "20:00",
        },
      ],
      companyId: companyId,
      default: true,
    };
    await ApiPost("staff/", body);
  };

  const createCategory = async (companyId) => {
    let body1 = {
      categoryName: "Unassign",
      categoryColor: "#D1FFF4",
      companyId: companyId,
    };
    let body2 = {
      categoryName: "Hair Treatment",
      categoryColor: "#FFD3AA",
      companyId: companyId,
      staff: [],
      isActive: true,
    };
    let resp = await ApiPost("category/", body1);
    try {
      if (resp.data.status === 200) {
        //create default services
        createDefaultService(resp.data.data._id, companyId);
        let res = await ApiPost("category/", body2);
        try {
          if (res.data.status === 200) {
            //create default services
            createServices(res.data.data._id, companyId);
          }
        } catch (er) {
          console.log(er);
        }
      }
    } catch (er) {
      console.log(er);
    }
  };

  const createColor = async (companyId, color) => {
    let payload = {
      name: color,
      companyId: companyId,
    };
    await ApiPost("colour/", payload);
  };

  const createDefaultService = async (categoryId, companyId) => {
    const body = {
      categoryId: categoryId,
      serviceName: "Slot",
      duration: "30",
      amount: "0",
      companyId: companyId,
      default: true,
    };
    await ApiPost("service/", body);
  };

  const createServices = async (categoryId, companyId) => {
    const body1 = {
      categoryId: categoryId,
      serviceName: "Hair Wash",
      duration: 15,
      amount: 150,
      tax: {},
      companyId: companyId,
      productConsumptions: [],
      isActive: true,
    };
    const body2 = {
      categoryId: categoryId,
      serviceName: "Hair Cut",
      duration: 40,
      amount: 500,
      tax: {},
      companyId: companyId,
      productConsumptions: [],
      isActive: true,
    };
    await ApiPost("service/", body1);
    await ApiPost("service/", body2);
  };

  const createPaymentMethod = async (companyId, method, status) => {
    let payload = {
      paymentType: method,
      companyId: companyId,
      default: status,
    };
    await ApiPost("payment", payload);
  };

  useEffect(() => {
    if (!fullName || !phoneNumber || !email || !password) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [fullName, phoneNumber, email, password]);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setEr();
    }, 1500);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="full-page-modal-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => toggle()}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>{editData ? "Edit manager" : "Add new account manager"}</h2>
          </div>
          {disable ? (
            <div className="blue-button" style={{ opacity: 0.5 }}>
              <button>{editData ? "Edit manager" : "Add Account Manager"}</button>
            </div>
          ) : (
            <div className="blue-button" onClick={() => handleAddManager()}>
              <button>{editData ? "Edit manager" : "Add Account Manager"}</button>
            </div>
          )}
        </div>
        <div className="full-page-body-top-alignment">
          <div className="container">
            <div className="box-center-alignment">
              <div className="account-data-box">
                <div className="box-title">
                  <p>Account data</p>
                </div>
                <div className="box-body-alignment">
                  <div className="input">
                    <label>Full name  <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["fullname"]}{" "}
                      </span></label>
                    <input type="text" value={fullName} placeholder="e.g. Gada Hair salon" onChange={(e) => setFullName(toTitleCase(e.target.value))} />
                  </div>
                  <div className="input">
                    <label>Mobile number  <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["mobilenumber"]}{" "}
                      </span></label>
                    <input
                      type="number"
                      placeholder="12345 67890"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="input">
                    <label>Email  <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["email"]}{" "}
                      </span></label>
                    <input type="text" placeholder="e.g. gadasalon@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="input">
                    <label>Password  <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {errors["password"]}{" "}
                      </span></label>
                    <div className="relative-div">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="e.g. Jethalal Gada"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <div className="icon-alignment">
                        <img
                          src={showConfirmPassword ? EyeIcon : EyeOnIcon}
                          alt="EyeIcon"
                          onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {success && <Success modal={success} toastmsg={toastmsg} />}
    </>
  );
}
