import React, { useEffect, useState } from "react";
import "./analyticsModal.scss";
import CloseIcon from "../../../assets/icons/Close.svg";
import InvoiceIcon from "../../../assets/icons/invoice-icon.svg";
import UserIcon from "../../../assets/icons/user-icon.svg";
import DownIcon from "../../../assets/icons/input-down.svg";
import CalendarIcon from "../../../assets/icons/calendar_analytics.svg";
import CheckSquareIcon from "../../../assets/icons/check-square.svg";
import CustomersIcon from "../../../assets/icons/customers_icon.svg";
import StaffIcon from "../../../assets/icons/staff_icon.svg";
import FilterUserModal from "../../adminDashboard/filterUserModal";
import { useSelector } from "react-redux";
import moment from "moment";
import { ApiPost } from "../../../helpers/API/ApiData";
import { Line } from "react-chartjs-2";
import { generateMonthLablesByNumberOfMonths } from "../../../utils/commonFunctions";
import { commonLineChartOptions } from "../../../utils/const";
import Loader from "../../common/Loader";

export default function AnalyticsModal(props) {
  const { toggle } = props;
  const { value: allUsers } = useSelector((state) => state.users);
  // defaults.global.defaultFontFamily = "Poppins";

  // chart variables and functions start =============================
  let labels = [];

  // chart variables and functions end ===============================

  const [loader, setLoader] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [showSelectUsersModal, setShowSelectUsersModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState();
  const [generatedLabels, setGeneratedLabels] = useState([]);
  const [invoiceChartData, setInvoiceChartData] = useState([]);
  const [invoiceChartTotal, setInvoiceChartTotal] = useState("00000");
  const [appointmentChartData, setAppointmentChartData] = useState([]);
  const [appointmentChartTotal, setAppointmentChartTotal] = useState("00000");
  const [transactionsChartData, setTransactionsChartData] = useState([]);
  const [letChartsData, setLetChartsData] = useState([]);

  const handleSetSelectedUsers = (users) => {
    setSelectedUsers(users);
    localStorage.setItem("analyticsSelectedUsers", JSON.stringify(users));
  };

  const handleSetSelectedMonths = (months) => {
    setSelectedMonths(months);
    setDropdown(false);
    labels = generateMonthLablesByNumberOfMonths(months);
    setGeneratedLabels(labels);
    localStorage.setItem("analyticsSelectedMonths", JSON.stringify(months));
  };

  const [totalNumbers, setTotalNumbers] = useState({
    appointmentList: 0,
    customerList: 0,
    invoiceList: 0,
    staffList: 0,
  });

  const selectUsersModalToggle = async (key) => {
    setShowSelectUsersModal(!showSelectUsersModal);
  };

  const fetchSalonTotalData = async (salons) => {
    let payload = {
      startTime: "2021-01-01",
      endTime: moment().add(1, "days").format("YYYY-MM-DD"),
      companyId: salons?.map((user) => user?.accountData[0]?.companyId),
    };

    await ApiPost("admin/count/datewise/report", payload)
      .then((res) => {
        if (res?.data?.status === 200) {
          setTotalNumbers(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchDataForMonths = async (months, users) => {
    setLoader(true);
    let companyIds = users?.map((user) => user?.accountData[0]?.companyId);
    let selectedMonths = months;
    let monthsSelectedFromNow = moment()
      .subtract(selectedMonths - 1, "months")
      .format("YYYY-MM-DD");
    let allmonthsInBetween = [];
    for (let i = 0; i < selectedMonths; i++) {
      allmonthsInBetween.push(moment(monthsSelectedFromNow).add(i, "months").format("YYYY-MM-DD"));
    }

    let allMonthsStartDateEndDatesPayload = [];

    for (let i = 0; i < allmonthsInBetween.length; i++) {
      let startTime = moment(allmonthsInBetween[i]).startOf("month").format("YYYY-MM-DD");
      let endTime = moment(moment(allmonthsInBetween[i]).endOf("month").add(1, "days")).format("YYYY-MM-DD");
      allMonthsStartDateEndDatesPayload.push({ startTime, endTime, companyId: companyIds });
    }

    if (allMonthsStartDateEndDatesPayload?.length > 0) {
      let allCompanyData = [];

      await Promise.all(
        allMonthsStartDateEndDatesPayload?.map(async (payload, index) => {
          await ApiPost("admin/count/datewise/report", payload)
            .then((res) => {
              if (res?.data?.status === 200) {
                allCompanyData.push({ ...res?.data?.data, month: moment(payload?.startTime).format("MMM YY"), index });
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
      )
        .then(() => {
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });

      if (allCompanyData?.length > 0) {
        let sortedAllCompanyData = allCompanyData?.sort((a, b) => a.index - b.index);
        setInvoiceChartData(sortedAllCompanyData?.map((data) => data?.invoiceList));
        setInvoiceChartTotal(sortedAllCompanyData?.reduce((a, b) => a + b?.invoiceList, 0));
        setAppointmentChartData(sortedAllCompanyData?.map((data) => data?.appointmentList));
        setAppointmentChartTotal(sortedAllCompanyData?.reduce((a, b) => a + b?.appointmentList, 0));
        setTransactionsChartData(sortedAllCompanyData?.map((data) => data?.invoiceList + data?.appointmentList));
      }
    }
  };

  // chart data start --------------------------------------------------

  let newOptions = {
    ...commonLineChartOptions,
  };

  labels = generatedLabels;
  let salonsInvoiceAppointmentChartData = {
    labels,
    datasets: [
      {
        label: "Appointment",
        data: appointmentChartData,
        borderColor: "#46BFBD",
        fill: false,
        backgroundColor: "#46BFBD",
      },
      {
        label: "Invoices",
        data: invoiceChartData,
        borderColor: "#FDB45C",
        fill: false,
        backgroundColor: "#FDB45C",
      },
    ],
  };

  let salonsTrnasactionsChartData = {
    labels,
    datasets: [
      {
        label: "Transaction",
        data: transactionsChartData,
        borderColor: "#46BFBD",
        fill: false,
        backgroundColor: "#46BFBD",
      },
    ],
  };

  // chart data end ----------------------------------------------------

  useEffect(() => {
    if (selectedUsers?.length > 0) {
      fetchSalonTotalData(selectedUsers);
      fetchDataForMonths(selectedMonths, selectedUsers);
    }
  }, [selectedUsers, selectedMonths]);

  useEffect(() => {
    if (localStorage.getItem("analyticsSelectedUsers")) {
      setSelectedUsers(JSON.parse(localStorage.getItem("analyticsSelectedUsers")));
    }
    if (localStorage.getItem("analyticsSelectedMonths")) {
      handleSetSelectedMonths(JSON.parse(localStorage.getItem("analyticsSelectedMonths")));
    } else if (!selectedMonths) {
      handleSetSelectedMonths("6");
    }
  }, []);

  return (
    <div>
      {loader && <Loader />}
      <div className="analytics-modal-wrapper-contnet-alignment">
        <div className="analytics-modal-header">
          <div className="all-content-alignment">
            <div onClick={toggle}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <p>Analytics</p>
          </div>
        </div>
        <div className="analytics-modal-body-content-alignment">
          <div className="body-first-header-alignment">
            <div className="total-icon">
              <button>
                <img src={CheckSquareIcon} alt="CalendarIcon" />
                <span>{(totalNumbers?.invoiceList + totalNumbers?.appointmentList)?.toLocaleString()}</span>
              </button>
            </div>
            <div className="rest-header">
              <div className="left-content-alignment">
                <button>
                  <img src={InvoiceIcon} alt="InvoiceIcon" />
                  <span>{totalNumbers?.invoiceList?.toLocaleString()}</span>
                </button>
                <button>
                  <img src={CalendarIcon} alt="CalendarIcon" />
                  <span>{totalNumbers?.appointmentList?.toLocaleString()}</span>
                </button>
                <button>
                  <img src={CustomersIcon} alt="CustomersIcon" />
                  <span>{totalNumbers?.customerList?.toLocaleString()}</span>
                </button>
                <button>
                  <img src={StaffIcon} alt="StaffIcon" />
                  <span>{totalNumbers?.staffList?.toLocaleString()}</span>
                </button>
              </div>
              <div className="right-content-alignment">
                <div className="all-user-selected">
                  <button onClick={selectUsersModalToggle}>
                    <img src={UserIcon} alt="UserIcon" />
                    <span>
                      {selectedUsers?.length === 0 ? "No" : selectedUsers?.length === allUsers?.length ? "All" : selectedUsers?.length} users selected
                    </span>
                  </button>
                </div>
                <div className="last-month-input-box">
                  <div className="input-relative" onClick={() => setDropdown(!dropdown)}>
                    <input type="text" placeholder={`Last ${selectedMonths} months`} />
                    <div className="down-icon-alignment">
                      <img src={DownIcon} alt="DownIcon" />
                    </div>
                  </div>
                  <div className={dropdown ? "input-dropdown input-dropdown-show" : "input-dropdown input-dropdown-hidden"}>
                    <div className="input-dropdown-design">
                      <ul>
                        {["3", "6", "12", "18"]?.map((item) => (
                          <li onClick={() => handleSetSelectedMonths(item)}>Last {item} months</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-vs-appontments">
            <h4>Invoice vs appointments</h4>
            <div className="two-form-group">
              <button>
                <img src={InvoiceIcon} alt="InvoiceIcon" />
                <span>{invoiceChartTotal}</span>
              </button>
              <button>
                <img src={CalendarIcon} alt="InvoiceIcon" />
                <span>{appointmentChartTotal}</span>
              </button>
            </div>
            <div className="chart-image-style">
              <Line options={newOptions} data={salonsInvoiceAppointmentChartData} height="297px" />
            </div>
          </div>
          <div className="invoice-vs-appontments">
            <h4>{invoiceChartTotal + appointmentChartTotal} Transactions</h4>

            <div className="chart-image-style">
              <Line options={newOptions} data={salonsTrnasactionsChartData} height="297px" />
            </div>
          </div>
        </div>
      </div>
      {showSelectUsersModal && (
        <FilterUserModal
          toggle={selectUsersModalToggle}
          allUsers={allUsers}
          selectedUsers={selectedUsers}
          setSelectedUsers={handleSetSelectedUsers}
          from="analytics"
        />
      )}
    </div>
  );
}
