import React, { useEffect, useState } from "react";
import "./PromoteModal.scss";
import CloseIcon from "../../../assets/icons/new-close.svg";
import DownloadIcon from "../../../assets/icons/download-icon.svg";
import { ApiGet } from "../../../helpers/API/ApiData";
import Auth from "../../../helpers/Auth";
import moment, { fn } from "moment";
import ViewPromoteContentModal from "../ViewPromoteContentModal";
import * as XLSX from "xlsx";
import Loader from "../../common/Loader";

export default function PromoteModal(props) {
  const { promoteRequestsModal, setPromoteRequestsModal } = props;
  const userInfo = Auth.getUserDetail();

  const [viewContentModal, setViewContentModal] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [viewampaignData, setViewCampaignData] = useState();
  const [loader, setLoader] = useState(false);

  const toggleViewContentModal = (content) => {
    setViewContentModal(!viewContentModal);
    if (content) {
      setViewCampaignData(content);
    }
  };

  useEffect(async () => {
    await ApiGet("campaign/admin/allcompany")
      .then((res) => {
        console.log("campaignPayment", res?.data?.data);
        setCampaignData((res?.data?.data).reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDownloadContacts = (data) => {
    setLoader(true);
    let thisCampaignData = data;
    let thisCampeignCustomers = data?.customerId;

    if (data?.customerId?.length === 0) return setLoader(false);

    let toDownloadCustomersData = thisCampeignCustomers.map((customer) => {
      return {
        name: customer?.firstName,
        phone: customer?.mobileNumber,
      };
    });

    exportToCSV(
      toDownloadCustomersData,
      thisCampaignData?.company_Id?.businessName?.split(" ")?.join("_") +
        "_" +
        moment(thisCampaignData?.campaignDate).format("dddd DD/MMM/YY") +
        ".xlsx"
    );
  };

  const exportToCSV = async (finalDataDetail, fileName) => {
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(finalDataDetail);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Mobile No."]], { origin: "A1" });

    /* calculate column width */
    const max_width = finalDataDetail.reduce((w, r) => Math.max(w, r.name.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    setLoader(false);
    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="account-modal-manager-wrapper">
        <div className="full-page-header-alignment">
          <div className="header-title">
            <div onClick={() => setPromoteRequestsModal(!promoteRequestsModal)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <h2>Promote</h2>
          </div>
        </div>
        <div className="full-page-body-top-alignment">
          <div className="all-content-alignment">
              <div className="account-manager-table-design" style={{width:"1400px"}}>
                <table>
                  <tr>
                    <td colSpan={7}>
                      <div className="all-content-alignment-header">
                        <h3>Total Campaign - {campaignData?.length}</h3>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th align="left">#</th>
                    <th align="left">Daet & time created</th>
                    <th align="left">Salon name</th>
                    <th align="left">WhatsApp number</th>
                    <th align="left">Date & time scheduled</th>
                    <th align="left"># of customers</th>
                    <th align="center">Content</th>
                    <th align="center">Customer list</th>
                  </tr>
                  {campaignData?.map((content, i) => {
                    return (
                      <tr>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{i + 1}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{moment(content?.created).format("DD MMM ‘YY, hh:mm A")}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{content?.company_Id?.businessName}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{content?.whatsappNumber}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{moment(content?.campaignDate).format("DD MMM ‘YY, hh:mm A")}</span>
                          </div>
                        </td>
                        <td align="left">
                          <div className="normal-text-style">
                            <span>{content?.customerId?.length} customers</span>
                          </div>
                        </td>
                        <td align="center">
                          <div className="normal-text-style" onClick={() => toggleViewContentModal(content)}>
                            <span className="blue-text-style">Content</span>
                          </div>
                        </td>
                        <td align="center">
                          <div className="download-button-container" onClick={() => handleDownloadContacts(content)}>
                            <img src={DownloadIcon} alt="download" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
      </div>
      {viewContentModal && <ViewPromoteContentModal toggle={toggleViewContentModal} viewampaignData={viewampaignData} />}
    </>
  );
}
