import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "../header";
import Home from "../home";


const DefaultLayout = ({ children, match }) => (
  <> 
    <Header />   
      {children}
  </>
);


function Layout() {
  return (
    <BrowserRouter>
      <Switch>
        <RouteWrapper
          exact={true}
          path="/"
          component={Home}
          layout={DefaultLayout}
        />
        <Redirect exact from="/login" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
export default Layout;
