import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Layout from "./Layout";
import Login from "../login";


const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.getUserDetail() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const UnAuthRoutes = () => {

  return (
    <Switch>
         <Route exact path="/login" component={Login} />
         <ProtectedRoute path="/" component={Layout} />
    </Switch>
  );
};

export default UnAuthRoutes;