import { useCallback } from "react";
import * as XLSX from "xlsx";


export const useExcelDownload = () => {
  const downloadExcel = useCallback((data , filename) => {
    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the binary array
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  }, []);

  return downloadExcel;
};
